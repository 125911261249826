import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import { ConfigProvider, Form, Input, Modal, QRCode, Result, Spin, message, theme } from "antd";
import { useNavigate } from "react-router-dom";

import "../styles/login.scss";

import AuthService from "../services/auth.service";

import { IUserLogin } from "../interfaces/auth.interface";
import { getErrorMsgKey } from "../utils/ui";
import authService from "../services/auth.service";
import { adminAccount } from "../utils/constants";
import userService from "../services/user.service";

const LoginPage: React.FC = () => {
  const { darkAlgorithm } = theme;
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  // let location = useLocation();
  const [resetPassForm] = Form.useForm();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isWeChatModalOpen, setIsWeChatModalOpen] = useState(false);
  const [isForgetPassModalOpen, setIsForgetPassModalOpen] = useState(false);
  const [isForgetPassEmailSent, setIsForgetPassEmailSent] = useState(false);

  // let from = location.state?.from?.pathname || "/";

  const handleLanguageChange = () => {
    // Toggle between CN and EN
    const newLanguage = i18n.language === "en-US" ? "zh-CN" : "en-US";
    i18n.changeLanguage(newLanguage);
  };

  const handleWechatLogin = (event: any) => {
    event.preventDefault();
    setIsWeChatModalOpen(true);
  };

  const handleCancelWechatLogin = () => {
    setIsWeChatModalOpen(false);
  };

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userAccount: IUserLogin = {
      username: email,
      password: password,
    };
    // console.info(`login ${JSON.stringify(userAccount)}`);
    setLoading(true);
    AuthService.login(userAccount)
      .then(() => {
        // console.info(
        //   `login btn event finished with token ${AuthService.token?.access_token}, start navigate to dashboard`
        // );
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        message.error(t(`error.${getErrorMsgKey(err)}`));
      });
  };

  const handleForgetPass = () => {
    setIsForgetPassEmailSent(false);
    setIsForgetPassModalOpen(true);
  };

  const handleCancelForgetPass = () => {
    setIsForgetPassEmailSent(false);
    setIsForgetPassModalOpen(false);
  };

  const handleSendResetPassEmail = async () => {
    await authService.login(adminAccount);
    const userlist = await userService.readUserList(true);
    const targetUser = userlist.find(
      (user) => user.email === resetPassForm.getFieldValue("email")
    );
    if (!targetUser) {
      message.error(`${t("error.UserNotFound")}`);
      return;
    }
    await userService.sendResetPass(targetUser.id);
    await authService.logout();
    setIsForgetPassEmailSent(true);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
    <Spin spinning={loading}>
      <Modal
        title={t("label.wechatQR")}
        open={isWeChatModalOpen}
        onCancel={handleCancelWechatLogin}
        centered
        width={230}
        footer={[]}
      >
        <QRCode value="https://weixin.qq.com/" />
      </Modal>
      <Modal
        title={t("label.forgetPass")}
        open={isForgetPassModalOpen}
        onOk={handleSendResetPassEmail}
        onCancel={handleCancelForgetPass}
        okText={t("btn.send")}
        cancelText={t("btn.cancel")}
        footer={isForgetPassEmailSent ? null : undefined}
        centered
      >
        {isForgetPassEmailSent ?  (
          <>
          <Result
            className="result"
            status="success"
            title={t("label.sendResetPassEmailSuccessful")}
          /></>
        ) : (
          <>
            <Form
              name="resetPass"
              form={resetPassForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 800, marginRight: "50px" }}
              scrollToFirstError
              autoComplete="off"
            >
              <Form.Item
                name="email"
                label={t("register.emailLab")}
                rules={[
                  {
                    type: "email",
                    message: `${t("register.inputInvalidEmailAlert")}`,
                  },
                  {
                    required: true,
                    message: `${t("register.inputEmailAlert")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard
              className="bg-dark text-white my-5 mx-auto"
              style={{ borderRadius: "1rem", maxWidth: "400px" }}
            >
              <div className="language-switcher">
                <span
                  className={`language-option ${
                    i18n.language === "zh-CN" ? "selected" : ""
                  }`}
                  onClick={handleLanguageChange}
                >
                  ZH
                </span>
                <span
                  className={`language-option ${
                    i18n.language === "en-US" ? "selected" : ""
                  }`}
                  onClick={handleLanguageChange}
                >
                  EN
                </span>
              </div>
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <h2 className="fw-bold mb-2 text-uppercase">
                  {t("login.title")}
                </h2>
                <p className="text-white-50 mb-4">{t("login.subTitle")}</p>
                <form
                  className="d-flex flex-column align-items-center mx-auto w-100"
                  onSubmit={handleLogin}
                >
                  <MDBInput
                    wrapperClass="mb-4 mx-5 w-100"
                    labelClass="text-white"
                    label={t("login.emailLab")}
                    id="formControlLgEmail"
                    type="email"
                    size="lg"
                    style={{ color: "white" }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <MDBInput
                    autoComplete=""
                    wrapperClass="mb-4 mx-5 w-100"
                    labelClass="text-white"
                    label={t("login.passLab")}
                    id="formControlLgPass"
                    type="password"
                    size="lg"
                    style={{ color: "white" }}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <p className="small mb-3 pb-lg-2">
                    <a
                      className="text-white-50"
                      href="#!"
                      onClick={handleForgetPass}
                    >
                      {t("login.forgetPass")}
                    </a>
                  </p>

                  <MDBBtn outline className="mx-2 px-5" color="white" size="lg">
                    {t("login.loginButt")}
                  </MDBBtn>
                </form>

                <div className="text-center mt-3">
                  <p>{t("login.otherSignUp")}</p>
                  <MDBBtn
                    tag="a"
                    color="none"
                    className="mb-2"
                    onClick={handleWechatLogin}
                    style={{ color: "white" }}
                  >
                    <MDBIcon far icon="comments" size="lg" />
                  </MDBBtn>
                </div>

                <div>
                  <p className="mb-0 mt-2">
                    {t("login.noAccount")}{" "}
                    <a href="/register" className="text-white-50 fw-bold m-1">
                      {t("login.signUp")}
                    </a>
                  </p>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Spin>
    </ConfigProvider>
  );
};

export default LoginPage;
