export enum Gender {
  Male = "m",
  Female = "f",
  Other = "o"
}

export enum GrundfosOrgCode {
  GCH = "GCH",
}

export interface IUserDetails {
  phoneNation: string;
  phoneNr: string;
  gender: Gender;
  organization: string;
}

export enum UserGroup {
  User = "user",
  Admin = "admin",
}

export interface IRealmRole {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

export interface IUserCreation {
  emailVerified: boolean;
  enabled: true;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  attributes: IUserDetails;
  credentials: [
    {
      type: "password";
      value: string;
      temporary: boolean;
    }
  ];
  realmRoles?: UserGroup[];
}

export interface IUserUpdate {
  username?: string;
  enabled?: boolean;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  attributes?: IUserDetails;
  requiredActions?: string[];
}

// get from /userinfo api
export interface IUserInfo extends IUserDetails {
  sub: string;
  email_verified: boolean;
  preferred_username: string;
  name: string;
  family_name: string;
  given_name: string;
  realm_access: { roles: string[] };
}

// get from /users api
export interface IUser {
  id: string;
  createdTimestamp: number;
  // now same as email
  username: string;
  enabled: boolean;
  /** Time-based One-Time Password:
   * type of two-factor authentication (2FA) method used to enhance the security of user accounts.
   * In Keycloak, TOTP refers to the Time-based One-Time Password feature associated with a user's account.
   */
  // totp: boolean;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  attributes: {
    phoneNation: string[];
    phoneNr: string[];
    gender: Gender[];
    organization: string[];
  };
  // disableableCredentialTypes: any[];
  requiredActions: string[];
  // notBefore: number;
  // access: any;
}

// show the user list in ui
export interface IUserData {
  id: string;
  createdTimestamp: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  roles: string[];
  email: string;
  emailVerified: boolean;
  organization: string;
  phoneNation: string;
  phoneNr: string;
  requiredActions: string[];
}
