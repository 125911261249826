import RequestService from "../utils/request";

import { ITelemetry, ITelemtryStatistics } from "../interfaces/telemetry.interface";

class TelemetryService {
  async getTelemetryList(deviceId: string): Promise<string[]> {
    return RequestService.get<string[]>(`/telemetry/summary/type?deviceId=${deviceId}`);
  }

  async getIdList(telemetryType: string): Promise<string[]> {
    return RequestService.get<string[]>(`/telemetry/summary/id/${telemetryType}`);
  }

  async getTimeserialDto(id: string, type: string, start: string, end: string, isDesc: boolean): Promise<ITelemetry[]> {
    return RequestService.get<ITelemetry[]>(`/telemetry/time-serial/${type}/${id}`, undefined, { start, end, isDesc });
  }

  // async getTelemetryStatistic(telemetryTypes: string[], timeslot: 'hour' | 'day' | 'month'): Promise<ITelemtryStatistics[]> {
  //   return RequestService.get<ITelemtryStatistics[]>('/telemetry/statistics', undefined, {types: telemetryTypes, time: timeslot})
  // }

  async getDataStatistic(telemetryTypes: string[]): Promise<ITelemtryStatistics[]> {
    return RequestService.get<ITelemtryStatistics[]>('/database/statistics', undefined, {types: telemetryTypes})
  }
}

const telemetryService = new TelemetryService();
export default telemetryService;
