import * as lodash from "lodash";

import RequestService from "../utils/request";

import {
  DeviceTwinInfo,
  DeviceTwinStatus,
  IDevice,
  IDeviceTwin,
} from "../interfaces/device.interface";
import { getDevicePropertyValue } from "../utils/device";

class DeviceTwinService {
  async getAllDevices(): Promise<IDeviceTwin[]> {
    try {
      const devices = await this._getAllDevices();
      return devices.map<IDeviceTwin>((device: IDevice) => {
        const info = getDevicePropertyValue<DeviceTwinInfo>("info", device, { name: '--',  projectCode: '--', location: '--'});
        const componients = getDevicePropertyValue<string[] | undefined>("componients", device, undefined);
        if (componients) {
          const formattedComponients = new Set(componients.map((item) => { return device.deviceId + '-' + item; }));
          return {
            id: device.deviceId,
            name: info.name ?? '--',
            projectCode: info.projectCode ?? '--',
            location: info.location ?? '--',
            tags:
              lodash.isEqual(device.tags, {}) || device.tags === null
                ? "--"
                : Object.entries(device.tags).map(
                    ([key, value]) => `${key}: ${value}`
                  ),
            componients: Array.from(formattedComponients),
            status: this._getDeviceStatus(device),
          };
        }
        // if (!componients.has(device.deviceId)) {
        //   componients.forEach((item) => {
        //     componients.add(device.deviceId + "-" + item);
        //     componients.delete(item);
        //   });
        // }
        return {
          id: device.deviceId,
          name: info.name ?? '--',
          projectCode: info.projectCode ?? '--',
          location: info.location ?? '--',
          tags:
            lodash.isEqual(device.tags, {}) || device.tags === null
              ? "--"
              : Object.entries(device.tags).map(
                  ([key, value]) => `${key}: ${value}`
                ),
          componients: [device.deviceId],
          status: this._getDeviceStatus(device),
        };
      });
    } catch (error) {
      console.log(`get device error ${error}`);
      // console.log(`get device error ${JSON.stringify(error)}`);
      // console.log(
      //   `get device error body ${JSON.stringify(lodash.get(error, "response"))}`
      // );
      throw new Error(lodash.get(error, "response.message"));
    }
  }

  async getDevice(id: string): Promise<IDeviceTwin> {
    try {
      const device = await this._getDevice(id);
      const componients = new Set(
        getDevicePropertyValue("componients", device, [device.deviceId])
      );
      return {
        id: device.deviceId,
        name: getDevicePropertyValue("name", device, "--"),
        projectCode: getDevicePropertyValue("projectCode", device, "--"),
        location: getDevicePropertyValue("location", device, "--"),
        tags:
          lodash.isEqual(device.tags, {}) || device.tags === null
            ? "--"
            : Object.entries(device.tags).map(
                ([key, value]) => `${key}: ${value}`
              ),
        componients: Array.from(componients),
        status: this._getDeviceStatus(device),
      };
    } catch (error) {
      console.log(`get device error ${error}`);
      throw new Error(lodash.get(error, "response.message"));
    }
  }

  async _getAllDevices(): Promise<IDevice[]> {
    const devices = await RequestService.get<IDevice[]>("/devices");
    return devices;
  }

  async _getDevice(id: string): Promise<IDevice> {
    const device = await RequestService.get<IDevice>(`/device/${id}`);
    return device;
  }

  _getDeviceStatus(device: IDevice): DeviceTwinStatus {
    if (device.status === "disabled") {
      return DeviceTwinStatus.Disabled;
    }
    if (device.connectionState === "Connected") {
      const systemStatus = getDevicePropertyValue('systemStatus', device, {});
      if (!systemStatus || !Object.values(systemStatus).includes(true)) {
        return DeviceTwinStatus.Online;
      }
      return DeviceTwinStatus.Warn;
    }
    return DeviceTwinStatus.Offline;
  }
}

const deviceTwinService = new DeviceTwinService();
export default deviceTwinService;
