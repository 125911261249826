import moment from "moment";

import { SelectProps } from "antd";

import { MenuItem } from "../interfaces/ui.interface";

export function getMenuItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group" | "avatar"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export function getSelectOptions(label: string[], value?: string[]): SelectProps['options'] {
  if (value && label.length !== value.length) {
    value = undefined;
  }
  return label.map((item: string, index) => {
    return {
      label: item,
      value: value ? value[index] : item
    }
  })
}

export function convertUTCToLocalTime(utc: string, format: string): string {
  if (!utc) {
    return "";
  }

  const utcMoment = moment.utc(utc);

  if (!utcMoment.isValid()) {
    return "";
  }

  const localTimestamp = utcMoment.local();

  if (format) {
    return localTimestamp.format(format);
  } else {
    return localTimestamp.toISOString();
  }
}

export function convertlocalTimeToUTC(localtime: string): string {
  const localTimestamp = moment(localtime);

  if (!localTimestamp.isValid()) {
    return "";
  }

  const utcTimestamp = localTimestamp.utc().toISOString();
  return utcTimestamp;
}

export function getErrorMsgKey(err: any): string {
  const errRes = err.response.data;
  const code = err.response.status;
  // bad request refers to network error 
  if (code && code === 500) {
    return 'network error';
  }
  // check if axios error
  if(!errRes) {
    console.error(`unkwnon err ${err}`);
    return 'unknown';
  }
  // check if keycloak error
  const errInfo = errRes.error_description;
  if (!errInfo) {
    console.error(`axios err ${JSON.stringify(errRes)}`);
    return 'unknown';
  }
  console.error(`keycloak err ${JSON.stringify(errInfo)}`);
  return errInfo;
}
