import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as lodash from "lodash";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  InputRef,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
  theme,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import "../styles/project.scss";

import AuthService from "../services/auth.service";
import DeviceTwinService from "../services/device.service";
import TelemetryService from "../services/telemetry.service";

import {
  Colors,
  EmptyValue,
  disableColor,
  offlineColor,
  onlineColor,
} from "../utils/constants";
import { EditableCellProps } from "../interfaces/ui.interface";
import { getErrorMsgKey } from "../utils/ui";
import {
  DeviceTwinStatus,
  IDeviceRow,
  IDeviceTwin,
} from "../interfaces/device.interface";

const DevicePage: React.FC = () => {
  const refreshInterval = 30000;
  
  const { darkAlgorithm } = theme;

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const [dataSource, setDataSource] = useState<IDeviceRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState<string[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [tableInEdit, setTableInEdit] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  const pageSize = 10;
  const roles = AuthService.getRoles() ?? [];
  const defaultColumns = [
    {
      title: t("table.id"),
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("table.deviceName"),
      dataIndex: "name",
      key: "deviceName",
      ellipsis: true,
      editable: true,
      width: 100,
    },
    {
      title: t("table.projectCode"),
      dataIndex: "projectCode",
      key: "projectCode",
      ellipsis: true,
      editable: true,
      width: 150,
    },
    {
      title: t("table.tags"),
      dataIndex: "tags",
      key: "tags",
      editable: true,
      width: 250,
      render: (tags: string[] | EmptyValue) => (
        <>
          {tags !== "--"
            ? tags.map((stackholder: string, index: number) => (
                <Tag color={Colors[index]} key={stackholder}>
                  {stackholder}
                </Tag>
              ))
            : tags}
        </>
      ),
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status: DeviceTwinStatus) => (
        <>
          {status === DeviceTwinStatus.Online ? (
            <Tag color={onlineColor}>{t("status.online")}</Tag>
          ) : status === DeviceTwinStatus.Offline ? (
            <Tag color={offlineColor}>{t("status.offline")}</Tag>
          ) : (
            <Tag color={disableColor}>{t("status.disabled")}</Tag>
          )}
        </>
      ),
    },
    {
      title: t("table.action"),
      dataIndex: "action",
      key: "action",
      width: 180,
      render: (_: any, record: IDeviceRow) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Popconfirm
              title="Are you sure you want to save the changes?"
              onConfirm={() => handleSave(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="save-button"
                type="primary"
                size="small"
                disabled={!roles.includes("admin")}
                icon={<SaveOutlined />}
              >
                {t("btn.save")}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to discard the changes?"
              onConfirm={() => handleClose(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="delete-button"
                type="primary"
                size="small"
                disabled={!roles.includes("admin")}
                danger
                icon={<CloseCircleOutlined />}
              >
                {t("btn.cancel")}
              </Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button
              disabled={!record.hasData}
              className="data-button"
              type="primary"
              size="small"
              icon={<LineChartOutlined />}
              onClick={() => handleChart(record)}
            >
              {t("btn.showData")}
            </Button>
            <Button
              className="edit-button"
              type="primary"
              size="small"
              disabled={!roles.includes("admin")}
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              {t("btn.edit")}
            </Button>
          </Space>
        );
      },
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const editableCell: React.FC<EditableCellProps<any>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "tags" ? (
        // Use input box for adding/editing stackholders
        <Space size={[0, 8]} wrap>
          <Space size={[0, 8]} wrap>
            {tags.map((tag: string, index: number) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    className="tagsInput"
                    ref={editInputRef}
                    key={tag}
                    size="small"
                    value={editInputValue}
                    defaultValue={tag}
                    onChange={handleTagEditInputChange}
                    onBlur={handleTagEditInputConfirm}
                    onPressEnter={handleTagEditInputConfirm}
                  />
                );
              }
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={true}
                  style={{ userSelect: "none" }}
                  onClose={() => handleTagClose(tag)}
                >
                  <span
                    onDoubleClick={(e) => {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </Space>
          {inputVisible ? (
            <Input
              ref={inputRef}
              className="stackholderInput"
              type="text"
              size="small"
              value={inputValue}
              defaultValue={""}
              onChange={handleTagInputChange}
              onBlur={handleTagInputConfirm}
              onPressEnter={handleTagInputConfirm}
            />
          ) : (
            <Tag
              style={{
                background: token.colorBgContainer,
                borderStyle: "dashed",
              }}
              onClick={showTagInput}
            >
              <PlusOutlined /> New Tag
            </Tag>
          )}
        </Space>
      ) : (
        <Input defaultValue={lodash.get(record, dataIndex)} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: any) => record.id === editingKey;
  const showTotal = (total: number, range: [number, number]) => {
    return (
      <span style={{ fontWeight: "bold" }}>
        Showing {range[0]}-{range[1]} of total {total} items
      </span>
    );
  };
  const handleTagClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const showTagInput = () => {
    setInputVisible(true);
  };
  const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const handleTagInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleTagEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };
  const handleTagEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  const handleEdit = (record: any) => {
    setTableInEdit(true);
    form.setFieldsValue({
      name: record.name,
    });

    setEditingKey(record.id!);
    setTags(
      record["tags"] ? (record["tags"] === "--" ? [] : record["tags"]) : []
    );
  };
  const handleClose = (record: any) => {
    setInputVisible(false);
    setTags(record["tags"] === "--" ? [] : record["tags"]);
    setEditInputIndex(-1);
    setInputValue("");
    setEditingKey("");
    setTableInEdit(false);
  };
  const handleSave = async (key: any) => {
    try {
      const updatedFields = await form.validateFields();
      const row = lodash.assign(key, updatedFields);
      row.tags = tags;
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // console.info(`check new existing data ${JSON.stringify(newData)}`);
        setDataSource(newData);
        setEditingKey("");
        setTags([]);
      } else {
        // console.info(`check new data ${JSON.stringify(newData)}`);
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
        setTags([]);
      }
      setTableInEdit(false);
    } catch (err: any) {
      message.error(t(`error.${getErrorMsgKey(err)}`));
      console.log("Validate Failed:", err);
    }
  };
  const handleChart = (record: IDeviceTwin) => {
    window.open(`/chart/${record.id}`, "_blank");
  };
  const refreshDeviceList = () => {
    setLoading(true);
    DeviceTwinService.getAllDevices()
      .then(async (data: IDeviceTwin[]) => {
        console.info(`get all devices ${JSON.stringify(data.map((dto) => dto.id))}`);
        let noDataDevices = JSON.parse(JSON.stringify(data)) as IDeviceTwin[];
        if (dataSource.length !== 0) {
          const hasDataDeviceIds = dataSource.filter((item) => item.hasData === true).map((item) => {return item.id});
          noDataDevices = noDataDevices.filter((item) => !hasDataDeviceIds.includes(item.id));
        }
        console.info(`get all no data devices ${JSON.stringify(noDataDevices.map((dto) => dto.id))}`);
        const rows = await Promise.all<IDeviceRow>(
          noDataDevices.map(async (deviceTwin: IDeviceTwin) => {
            let i = 0;
            const componentList = deviceTwin.componients;
            if (!componentList || componentList.length === 0) {
              const id = deviceTwin.id;
              const result = await TelemetryService.getTelemetryList(id);
              console.info(`get telemetry list ${JSON.stringify(result)} of id ${id}`);
              if (result.length !== 0) {
                return { ...deviceTwin, hasData: true};
              }
            } else {
              while (i < componentList.length) {
                const componentId = deviceTwin.componients[i];
                const result = await TelemetryService.getTelemetryList(componentId);
                console.info(`get telemetry list ${JSON.stringify(result)} of id ${componentId}`);
                if (result.length !== 0) {
                  return { ...deviceTwin, hasData: true };
                } else {
                  i++;
                }
              }
            }
            return { ...deviceTwin, hasData: false };
          })
        );
        setDataSource(rows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        message.error(t(`error.${getErrorMsgKey(err)}`));
        navigate('/devicelist');
      });
  };

  useEffect(() => {
    refreshDeviceList();
    const intervalId = setInterval(() => {
      if (!tableInEdit) {
        refreshDeviceList();
      }
    }, refreshInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [tableInEdit]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Spin spinning={loading}>
        <Form form={form} component={false}>
          <Table
            columns={columns}
            components={{
              body: {
                cell: editableCell,
              },
            }}
            dataSource={dataSource}
            rowKey={"id"}
            scroll={{ x: "max-content" }}
            rowClassName={() => "editable-row"}
            pagination={{
              pageSize,
              showTotal,
            }}
          />
        </Form>
      </Spin>
    </ConfigProvider>
  );
};

export default DevicePage;
