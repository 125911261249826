import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import "./styles/app.scss";

import EntryPage from "./pages/entry.page";
import LoginPage from "./pages/login.page";
import AuthService from "./services/auth.service";
import RegisterPage from "./pages/register.page";
import DiagramPage from "./pages/diagram.page";

const App: React.FC = () => {
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    let location = useLocation();
    const isAuth = AuthService.isLogined();

    if (!isAuth) {
      console.info(`no token found, return to login page`);
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    console.info(`valid token found, go to dashboard directly`);
    return children;
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route
        path="/chart/:id"
        element={
          <RequireAuth>
            <DiagramPage />
          </RequireAuth>
        }
      />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <EntryPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default App;
