import RequestService from "../utils/request";

import { IProject, ProjectFormDto } from "../interfaces/project.interface";

class ProjectService {
  async createProject(project: ProjectFormDto): Promise<void> {
    const newProject: IProject = {
      id: project.id,
      name: project.name,
      stackholders: project.stackholders,
      startDate: project.startDate,
      endDate: project.endDate,
      ideation: project.status.Ideation,
      conceptualization: project.status.Conceptualization,
      incubation: project.status.Incubation,
      viableBusiness: project.status.ViableBusiness,
      scaleSaling: project.status.ScaleSaling,
      archived: project.status.Archived
    };
    return RequestService.post(`/project`, [newProject]);
  }

  async getProjects(): Promise<ProjectFormDto[]> {
    const data = await RequestService.get<IProject[]>(`/project`);
    return data.map<ProjectFormDto>((item: IProject) => {
      return {
        id: item.id,
        name: item.name,
        stackholders: item.stackholders,
        startDate: item.startDate,
        endDate: item.endDate,
        status: {
          Ideation: item.ideation,
          Conceptualization: item.conceptualization,
          Incubation: item.incubation,
          ViableBusiness: item.viableBusiness,
          ScaleSaling: item.scaleSaling,
          Archived: item.archived,
        },
      };
    });
  }

  async updateProject(id: string, content: ProjectFormDto): Promise<void> {
    const updatedItem: IProject = {
      id: content.id,
      name: content.name,
      stackholders: content.stackholders,
      startDate: content.startDate,
      endDate: content.endDate,
      ideation: content.status.Ideation,
      conceptualization: content.status.Conceptualization,
      incubation: content.status.Incubation,
      viableBusiness: content.status.ViableBusiness,
      scaleSaling: content.status.ScaleSaling,
      archived: content.status.Archived
    };
    return RequestService.update(`/project?id=${id}`, updatedItem);
  }

  async archiveProject(id: string): Promise<void> {
    return RequestService.delete(`/project/${id}`);
  }
}

const projectService = new ProjectService();
export default projectService;
