import React from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Statistic, Table, Tag, theme } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import "../styles/module.scss";

import { Colors, EmptyValue } from "../utils/constants";
import { ModuleStatus } from "../interfaces/module.interface";

const ModulePage: React.FC = () => {
  const { t } = useTranslation();
  
  const { darkAlgorithm } = theme;

  const columns = [
    {
      title: t("table.id"),
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("table.moduleName"),
      dataIndex: "name",
      key: "projectName",
      ellipsis: true,
      // editable: true,
      width: 100,
    },
    {
      title: t("table.tags"),
      dataIndex: "tags",
      key: "tags",
      // editable: true,
      width: 300,
      render: (stackholders: string[] | EmptyValue) => (
        <>
          {stackholders !== "--"
            ? stackholders.map((stackholder: string, index: number) => (
                <Tag className="tags" color={Colors[index]} key={stackholder}>
                  {stackholder}
                </Tag>
              ))
            : stackholders}
        </>
      ),
    },
    {
      title: t("table.precision"),
      dataIndex: "precision",
      key: "precision",
      ellipsis: true,
      // editable: true,
      width: 100,
      render: (data: number) => {
        return (
          <Statistic
            style={{fontSize: "14px"}}
            value={data}
            precision={2}
            valueStyle={data >= 90 ? { color: "#3f8600" } : { color: "#cf1322" }}
            prefix={data >= 90 ? <CheckOutlined /> : <CloseOutlined />}
            suffix="%"
          />
        );
      },
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (data: ModuleStatus) => {
        let color = "processing";
        let label = "tag.developing";
        if (data === ModuleStatus.Deprecated) {
          color = "default";
          label = "tag.deprecated";
        } else if (data === ModuleStatus.Released) {
          color = "success";
          label = "tag.released";
        } else if (data === ModuleStatus.Training) {
          color = "lime";
          label = "tag.training";
        }
        return <Tag color={color}>{t(label)}</Tag>;
      },
    },
  ];

  const pageSize = 20;

  const showTotal = (total: number, range: [number, number]) => {
    return (
      <span style={{ fontWeight: "bold" }}>
        Showing {range[0]}-{range[1]} of total {total} items
      </span>
    );
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Table
        columns={columns}
        dataSource={[]}
        rowKey={"id"}
        scroll={{ x: "max-content" }}
        pagination={{
          pageSize,
          showTotal,
        }}
        // expandable={{
        //   expandedRowRender: (record: any) => {

        //   }
        // }}
      ></Table>
    </ConfigProvider>
  );
};

export default ModulePage;
