import axios from "axios";
import * as lodash from "lodash";

/**
 * The http/https request class that package axios to support format request and response with all API services
 */
class RequestService {
  private _convertObjToQuerys(obj?: object): string {
    let result = "";
    if (!obj || lodash.isEqual(obj, {})) {
      return result;
    }
    result = "?";
    for (const key in obj) {
      result += `${key}=${lodash.get(obj, key)}&`;
    }
    // remove last & in query str
    result = result.slice(0, -1);
    return result;
  }

  /**
   * Post axios request with url and data, it supports send json object as
   * url param and define own header
   * @param url string of url of post request
   * @param data any data that will be body of axios post request
   * @param headers request header that define content-type or other params, default is application/json
   * @param queryObj you can define param in header instead of this paramter 
   */
  async post(
    url: string,
    data: any,
    headers: Record<string, string> = { "content-type": "application/json"},
    queryObj?: object,
  ): Promise<void> {
    await axios.post(`${url}${this._convertObjToQuerys(queryObj)}`, data, {
      headers: headers,
    });
  }

  /**
   * get request from defined url and get desired type of data from it
   * @param url string of url of get request
   * @param headers request header that define content-type or other params, default is application/json
   * @param queryObj you can define param in header instead of this paramter 
   * @returns deined type of data directly
   */
  async get<T>(
    url: string,
    headers: Record<string, string> = { "content-type": "application/json"},
    queryObj?: object,
  ): Promise<T> {
    const response = await axios.get(
      `${url}${this._convertObjToQuerys(queryObj)}`,
      { headers: headers }
    );
    const data = response.data.data;
    if (lodash.isObject(data)) {
      return data as T;
    }
    return JSON.parse(data) as T;
  }

  /**
   * update request with url and data, it supports send json object as
   * url param and define own header
   * @param url string of url of update request
   * @param headers request header that define content-type or other params, default is application/json
   * @param queryObj you can define param in header instead of this paramter 
   */
  async update(
    url: string,
    data: any,
    headers: Record<string, string> = { "content-type": "application/json"},
    queryObj?: object,
  ): Promise<void> {
    await axios.put(`${url}${this._convertObjToQuerys(queryObj)}`, data, {
      headers: headers,
    });
  }

  /**
   * delete request with url and data, it supports send json object as
   * url param and define own header
   * @param url string of url of delete request
   * @param headers request header that define content-type or other params
   * @param queryObj you can define param in header instead of this paramter 
   */
  async delete(
    url: string,
    headers: Record<string, string> = { "content-type": "application/json"},
    queryObj?: object,
  ): Promise<void> {
    await axios.delete(`${url}${this._convertObjToQuerys(queryObj)}`, {
      headers: headers,
    });
  }
}

const requestService = new RequestService();
export default requestService; 
