import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ConfigProvider,
  Descriptions,
  DescriptionsProps,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  message,
  theme,
} from "antd";
import * as lodash from "lodash";
import {
  CheckCircleOutlined,
  StopOutlined,
  SaveOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import "../styles/user.scss";

import userService from "../services/user.service";

import { AvatarIcoCor } from "../utils/constants";
import {
  Gender,
  IUserCreation,
  IUserData,
  UserGroup,
} from "../interfaces/user.interface";
import { IProject } from "../interfaces/project.interface";
import { EditableCellProps } from "../interfaces/ui.interface";
import { getErrorMsgKey } from "../utils/ui";

const UserPage: React.FC = () => {
  const { t } = useTranslation();
  const { darkAlgorithm } = theme;

  const [newUserForm] = Form.useForm();
  const [editUserForm] = Form.useForm();

  const [dataSource, setDataSource] = useState([] as IUserData[]);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState("");

  const pageSize = 20;

  const isEditing = (record: IUserData) => record.id === editingKey;

  const refreshUesrList = () => {
    setLoading(true);
    userService
      .readUserList()
      .then((data: IUserData[]) => {
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(t(`error.${getErrorMsgKey(err)}`));
      });
  };

  const handleAddUser = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleSave = async (record: IUserData) => {
    try {
      const updatedFields = await editUserForm.validateFields();
      if (updatedFields.roles) {
        updatedFields.roles = [updatedFields.roles];
      }
      // console.info(`check updated fields ${JSON.stringify(updatedFields)}`);
      const row = lodash.assign<IUserData, Partial<IUserData>>(
        JSON.parse(JSON.stringify(record)),
        updatedFields
      );
      const newData = [...dataSource];
      const index = newData.findIndex((item) => record.id === item.id);
      // console.info(
      //   `check orginal row ${JSON.stringify(
      //     record
      //   )}, update fields ${JSON.stringify(
      //     updatedFields
      //   )}; new row ${JSON.stringify(row)} with index ${index}`
      // );
      const item = newData[index];
      const newRow = {
        ...item,
        ...row,
      };
      newData.splice(index, 1, newRow);
      if (record.roles[0] !== editUserForm.getFieldValue("roles")[0]) {
        // console.info(
        //   `role of user ${item.email} is updated from ${
        //     item.roles[0]
        //   } to ${editUserForm.getFieldValue("roles")}`
        // );
        await userService.assignRole(
          item.id,
          editUserForm.getFieldValue("roles")
        );
      }
      setDataSource(newData);
      setEditingKey("");
    } catch (err: any) {
      message.error(t(`error.${getErrorMsgKey(err)}`));
    }
  };

  const handleEdit = (record: IUserData) => {
    // console.info(`check handle edit record ${JSON.stringify(record)}`);
    setEditingKey(record.id);
  };

  const handleClose = (record: IUserData) => {
    setEditingKey("");
  };

  const handleCreateUser = async () => {
    try {
      await newUserForm.validateFields();
      const newUser: IUserCreation = {
        emailVerified: false,
        enabled: true,
        firstName: newUserForm.getFieldValue("firstName"),
        lastName: newUserForm.getFieldValue("lastName"),
        username: `${newUserForm.getFieldValue(
          "firstName"
        )} ${newUserForm.getFieldValue("lastName")}`,
        email: newUserForm.getFieldValue("email"),
        attributes: {
          phoneNation: newUserForm.getFieldValue("prefixPhone"),
          phoneNr: newUserForm.getFieldValue("phone"),
          gender: newUserForm.getFieldValue("gender"),
          organization: newUserForm.getFieldValue("organization"),
        },
        credentials: [
          {
            type: "password",
            value: newUserForm.getFieldValue("confirmPass"),
            temporary: false,
          },
        ],
        realmRoles: [newUserForm.getFieldValue("role")],
      };
      // console.info(`check new user ${JSON.stringify(newUser)}`);
      await userService.createUser(newUser);
      const list = await userService.readUserList();
      const user = list.find(
        (user) => user.email === newUserForm.getFieldValue("email")
      );
      if (!user) {
        message.error(`${t("error.UserNotFound")}`);
        setLoading(false);
        return;
      }
      await userService.sendActiveLetter(user.id);
      refreshUesrList();
      setIsCreateUserModalOpen(false);
    } catch (err: any) {
      // Validation failed, do nothing or handle error
      message.error(t(`error.${getErrorMsgKey(err)}`));
    }
  };

  const handleCancelCreateUser = () => {
    setIsCreateUserModalOpen(false);
  };

  const handleActiveUser = (record: IUserData) => {
    userService
      .activeUser(record.id)
      .then(refreshUesrList)
      .catch((err) => message.error(t(`error.${getErrorMsgKey(err)}`)));
  };

  const handleDisableUser = (record: IUserData) => {
    userService
      .disableUser(record.id)
      .then(refreshUesrList)
      .catch((err) => message.error(t(`error.${getErrorMsgKey(err)}`)));
  };

  const showTotal = (total: number, range: [number, number]) => {
    return (
      <span style={{ fontWeight: "bold" }}>
        Showing {range[0]}-{range[1]} of total {total} items
      </span>
    );
  };

  const defaultColumns = [
    {
      title: t("table.createdAt"),
      dataIndex: "createdTimestamp",
      key: "createdTimestamp",
    },
    {
      title: t("register.firstNameLab"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("register.lastNameLab"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("register.genderLab"),
      dataIndex: "gender",
      key: "gender",
      render: (gender: string) => (
        <>
          {gender === "m" ? (
            <div>{t("register.maleLab")}</div>
          ) : gender === "f" ? (
            <div>{t("register.femaleLab")}</div>
          ) : (
            <div>{t("register.otherLab")}</div>
          )}
        </>
      ),
    },
    {
      title: t("table.roles"),
      dataIndex: "roles",
      key: "roles",
      editable: true,
      render: (roles: string[]) => (
        <>
          {roles.length !== 0
            ? roles.map((role: string) => (
                <Tag color={lodash.get(AvatarIcoCor, role)} key={role}>
                  {role === "admin"
                    ? t("label.admin")
                    : role === "user"
                    ? t("label.user")
                    : role}
                </Tag>
              ))
            : "--"}
        </>
      ),
    },
    {
      title: t("table.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("table.enabled"),
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: boolean, record: IUserData) => (
        <>
          {enabled ? (
            record.emailVerified === true ? (
              <Tag color="success" key={"enabled"}>
                {t("status.enabled")}
              </Tag>
            ) : (
              <Tag color="warning" key={"enabled"}>
                {t("status.unverified")}
              </Tag>
            )
          ) : (
            <Tag color="error" key={"disabled"}>
              {t("status.disabled")}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: t("table.action"),
      dataIndex: "action",
      key: "action",
      // width: 150,
      render: (_: any, record: IUserData) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Popconfirm
              title="Are you sure you want to save the changes?"
              onConfirm={() => handleSave(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="save-button"
                type="primary"
                size="small"
                icon={<SaveOutlined />}
              >
                {t("btn.save")}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to discard the changes?"
              onConfirm={() => handleClose(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="delete-button"
                type="primary"
                size="small"
                danger
                icon={<CloseCircleOutlined />}
              >
                {t("btn.cancel")}
              </Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button
              className="edit-button"
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              {t("btn.edit")}
            </Button>
            <Popconfirm
              title="Are you sure you want to active this account?"
              onConfirm={() => handleActiveUser(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="edit-button"
                type="default"
                style={{ backgroundColor: "#5b8c00" }}
                size="small"
                icon={<CheckCircleOutlined />}
              >
                {t("btn.active")}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to disable this account?"
              onConfirm={() => handleDisableUser(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="delete-button"
                type="primary"
                size="small"
                danger
                icon={<StopOutlined />}
              >
                {t("btn.deactive")}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IUserData) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editableCell: React.FC<EditableCellProps<IProject>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "roles" ? (
        // Use input box for adding/editing stackholders
        <Select
          defaultValue={t(
            `label.${
              lodash.get(record, dataIndex)
                ? (lodash.get(record, dataIndex) as any)[0]
                : UserGroup.User
            }`
          )}
          style={{ width: 80 }}
          options={[
            { value: UserGroup.Admin, label: t("label.admin") },
            { value: UserGroup.User, label: t("label.user") },
          ]}
        />
      ) : (
        <Input defaultValue={lodash.get(record, dataIndex)} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    refreshUesrList();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Modal
        title={t("register.newUser")}
        open={isCreateUserModalOpen}
        onOk={handleCreateUser}
        onCancel={handleCancelCreateUser}
        okText={t("btn.add")}
        cancelText={t("btn.cancel")}
      >
        <Form
          name="createUser"
          form={newUserForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 800, marginRight: "50px" }}
          scrollToFirstError
          initialValues={{
            gender: Gender.Male,
            role: UserGroup.User,
            prefixPhone: "+86",
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("register.firstNameLab")}
            name="firstName"
            style={{
              marginTop: "30px",
            }}
            rules={[
              {
                required: true,
                message: `${t("register.inputFirstNameAlert")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("register.lastNameLab")}
            name="lastName"
            rules={[
              {
                required: true,
                message: `${t("register.inputLastNameAlert")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="gender" label={t("register.genderLab")}>
            <Select>
              <Select.Option value={Gender.Male}>
                {t("register.maleLab")}
              </Select.Option>
              <Select.Option value={Gender.Female}>
                {t("register.femaleLab")}
              </Select.Option>
              <Select.Option value={Gender.Other}>
                {t("register.otherLab")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="role" label={t("register.roleLab")}>
            <Select>
              <Select.Option value={UserGroup.Admin}>
                {t("label.admin")}
              </Select.Option>
              <Select.Option value={UserGroup.User}>
                {t("label.user")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t("register.passLab")}
            name="password"
            rules={[
              {
                required: true,
                message: `${t("register.inputpasswordAlert")}`,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPass"
            label={t("register.confirmPassLab")}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: `${t("register.inputpasswordAlert")}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`${t("register.inputpasswordConfirmAlert")}`)
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("register.emailLab")}
            rules={[
              {
                type: "email",
                message: `${t("register.inputInvalidEmailAlert")}`,
              },
              {
                required: true,
                message: `${t("register.inputEmailAlert")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="organization"
            label={t("register.addressLab")}
            rules={[
              {
                required: true,
                message: `${t("register.inputCompanyAlert")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("register.phoneLab")}
            rules={[
              { required: true, message: `${t("register.inputPhoneAlert")}` },
            ]}
          >
            <Input
              addonBefore={
                <Form.Item name="prefixPhone" noStyle>
                  <Select style={{ width: 70 }}>
                    <Select.Option value="+86">+86</Select.Option>
                    <Select.Option value="+45">+45</Select.Option>
                  </Select>
                </Form.Item>
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        onClick={handleAddUser}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        {t("btn.addUser")}
      </Button>
      <Spin spinning={loading}>
        <Form form={editUserForm} component={false}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={"id"}
            scroll={{ x: "max-content" }}
            components={{
              body: {
                cell: editableCell,
              },
            }}
            pagination={{
              pageSize,
              showTotal,
            }}
            expandable={{
              expandedRowRender: (record: IUserData) => {
                const items: DescriptionsProps["items"] = [
                  {
                    key: "1",
                    label: t("table.id"),
                    children: record.id,
                  },
                  {
                    key: "2",
                    label: t("table.organization"),
                    children: record.organization,
                  },
                  {
                    key: "3",
                    label: t("table.phoneNr"),
                    children: `${record.phoneNation} ${record.phoneNr}`,
                  },
                ];
                return (
                  <Descriptions
                    bordered
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    items={items}
                  />
                );
              },
            }}
          />
        </Form>
      </Spin>
    </ConfigProvider>
  );
};

export default UserPage;
