import { Observable, from, retry, catchError, throwError } from "rxjs";

import RequestService from "../utils/request";

import { ITelemtryStatistics } from "../interfaces/telemetry.interface";

class DatabaseStatisticService {
  public getDatabaseStoragePercentages(): Observable<ITelemtryStatistics[]> {
    return this._retryRequest(
      RequestService.get<ITelemtryStatistics[]>('/database/statistics')
    )
  }

  private _retryRequest(fun: any, retries = 3): Observable<any> {
    return from(fun).pipe(
      retry(retries),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}

const databaseInfoService = new DatabaseStatisticService();
export default databaseInfoService;
