import axios from "axios";

import authService from "../services/auth.service";

export function authInterceptor() {
  axios.interceptors.request.use((request) => {
    const url = request.url;
    // add auth header with jwt if account is logged in and request is to the api url
    const isAuthURL = (url && !url.includes('token')) || (url && !url.includes('logout'));
    if (authService.token && isAuthURL) {
      if (request.headers) {
        request.headers.Authorization = `${authService.token.token_type} ${authService.token?.access_token}`;
      } else {
        request.headers = {
          Authorization: `${authService.token.token_type} ${authService.token?.access_token}`,
        };
      }
    }

    return request;
  });
}
