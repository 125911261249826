import RequestService from "../utils/request";

import { ISystemCluster } from "../interfaces/system.interface";

class SystemService {
  async getClusterInfo(): Promise<ISystemCluster[]> {
    // const devices = await RequestService.get<ISystemCluster[]>("/sys");
    return [
      {
        id: "p2-cluster-0",
        cpu: Math.random(),
        ram: Math.random(),
        nrOfServices: 13
      }
    ]
  }
}

const systemService = new SystemService();
export default systemService;
