export enum ModuleStatus {
  Developing = 'Developing',
  Released = 'Released',
  Deprecated = 'Deprecated',
  Training = 'Training',
}

export interface IModule {
  id: string;
  name: string;
  tags: string[];
  precision: number;
  status: ModuleStatus;
  params?: Record<string, number>
}