import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import dayjs from "dayjs";
import * as lodash from "lodash";
import {
  Badge,
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
  theme,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import "dayjs/locale/zh-cn";
import "dayjs/locale/en";
import zhlocale from "antd/es/date-picker/locale/zh_CN";
import enlocale from "antd/es/date-picker/locale/en_US";

import "../styles/project.scss";

import AuthService from "../services/auth.service";
import ProjectService from "../services/project.service";

import { Colors } from "../utils/constants";
import {
  PhaseState,
  ProjectFormDto,
  ProjectStatus,
} from "../interfaces/project.interface";
import { convertUTCToLocalTime, getErrorMsgKey } from "../utils/ui";
import { EditableCellProps } from "../interfaces/ui.interface";
import { retryPromise } from "../utils/util";

interface ProjectExpandedDataType {
  id: string;
  phaseName: string;
  phaseEndDate: string;
  phaseState: PhaseState;
}

interface IProjectForm extends ProjectStatus {
  name?: string;
  stackholders?: string[];
  startDate?: string;
  endDate?: string;
}

const ProjectPage: React.FC = () => {
  const [form] = Form.useForm();
  const [newProjectForm] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { token } = theme.useToken();
  const { darkAlgorithm } = theme;

  // replace the mock data to real data from database
  const [dataSource, setDataSource] = useState<ProjectFormDto[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);
  const [confirmCreateProjectLoading, setConfirmCreateProjectLoading] = useState(false);

  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const roles = AuthService.getRoles() ?? [];
  const isEditing = (record: ProjectFormDto | ProjectExpandedDataType) =>
    record.id === editingKey;
  const pageSize = 10;

  const showTotal = (total: number, range: [number, number]) => {
    return (
      <span style={{ fontWeight: "bold" }}>
        Showing {range[0]}-{range[1]} of total {total} items
      </span>
    );
  };

  const handleTagClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showTagInput = () => {
    setInputVisible(true);
  };

  const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleTagInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleTagEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleTagEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleEdit = (record: ProjectFormDto) => {
    const startDate =
      convertUTCToLocalTime(record.startDate ?? "", "YYYY-MM-DD") !== ""
        ? dayjs(
            convertUTCToLocalTime(record.startDate!, "YYYY-MM-DD"),
            "YYYY-MM-DD"
          )
        : dayjs();

    const endDate =
      convertUTCToLocalTime(record.endDate ?? "", "YYYY-MM-DD") !== ""
        ? dayjs(
            convertUTCToLocalTime(record.endDate!, "YYYY-MM-DD"),
            "YYYY-MM-DD"
          )
        : dayjs();

    form.setFieldsValue({
      startDate: startDate,
      endDate: endDate,
      name: record.name,
    });

    setEditingKey(record.id!);
    setTags(record["stackholders"] ? record["stackholders"] : []);
  };

  const handleClose = (record: ProjectFormDto) => {
    form.resetFields();
    setInputVisible(false);
    setTags(record["stackholders"]);
    setEditInputIndex(-1);
    setInputValue("");
    setEditingKey("");
  };

  const handleSave = async (key: ProjectFormDto) => {
    try {
      const updatedFields: Partial<IProjectForm> = await form.validateFields();

      const projectState: ProjectStatus = {
        Ideation: key.status.Ideation ?? "",
        Conceptualization: key.status.Conceptualization ?? "",
        Incubation: key.status.Incubation ?? "",
        ViableBusiness: key.status.ViableBusiness ?? "",
        ScaleSaling: key.status.ScaleSaling ?? "",
        Archived: key.status.Archived ?? "",
      };

      const updatedKeys = lodash.keys(
        lodash.pickBy(updatedFields, lodash.identity)
      );
      const nullValueUpdateKeys = lodash.keys(
        lodash.pickBy(updatedFields, (val) => val === null || val === "")
      );
      const updatedStateKeys = Object.keys(projectState).filter((key) =>
        updatedKeys.includes(key)
      );
      const updatedProjectKeys = Object.keys(key).filter((key) =>
        updatedKeys.includes(key)
      );

      let needUpdate = false;
      const row: ProjectFormDto = JSON.parse(JSON.stringify(key));
      updatedProjectKeys.forEach((projectKey: string) => {
        let updateValue = lodash.get(updatedFields, projectKey);
        if (["startDate", "endDate"].includes(projectKey)) {
          updateValue = dayjs(updateValue).toISOString();
        }
        if (lodash.get(key, projectKey) !== updateValue) {
          lodash.set(row, projectKey, updateValue);
          needUpdate = true;
        }
      });
      if (
        nullValueUpdateKeys.length === 0 &&
        updatedStateKeys.length === 0 &&
        !needUpdate &&
        JSON.stringify(row.stackholders) === JSON.stringify(tags)
      ) {
        // console.info(`no any update on project data`);
        return;
      }
      row.stackholders = tags;
      if (nullValueUpdateKeys.length !== 0) {
        nullValueUpdateKeys.forEach((key) => {
          lodash.set(row, key, lodash.get(updatedFields, key));
        });
      }
      if (updatedStateKeys.length !== 0) {
        lodash.keys(projectState).forEach((key: string) => {
          if (updatedStateKeys.includes(key)) {
            lodash.set(
              projectState,
              key,
              dayjs(lodash.get(updatedFields, key)).toISOString()
            );
          } else if (lodash.get(projectState, key) === "") {
            lodash.set(projectState, key, undefined);
          }
        });
        row.status = projectState;
      }

      const newData = [...dataSource];
      const index = newData.findIndex((item) => key.id === item.id);

      // console.info(
      //   `check orginal row ${JSON.stringify(
      //     key
      //   )}, update fields ${JSON.stringify(
      //     updatedFields
      //   )}; new row ${JSON.stringify(row)} with index ${index}`
      // );

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }
      form.resetFields();
      setDataSource(newData);
      setEditingKey("");
      setTags([]);
      // console.info(`check new row ${JSON.stringify(row)}`);
      retryPromise(() => ProjectService.updateProject(row.id, row), 5, 5000)
        .then(() => {
          message.info(t("message.updateProjectSuccessful"));
        })
        .catch((err) => {
          console.error(`update project fail ${err}`);
          message.error(t("error.unknown"));
        });
    } catch (err: any) {
      message.error(t(`error.${getErrorMsgKey(err)}`));
    }
  };

  const handleAddProject = () => {
    setIsCreateProjectModalOpen(true);
  };

  const handleCreateProject = async () => {
    setConfirmCreateProjectLoading(true);
    try {
      await newProjectForm.validateFields();
      const status = {
        Ideation: newProjectForm.getFieldValue("ideation")
          ? dayjs(newProjectForm.getFieldValue("ideation")).toISOString()
          : undefined,
        Conceptualization: newProjectForm.getFieldValue("conceptualization")
          ? dayjs(
              newProjectForm.getFieldValue("conceptualization")
            ).toISOString()
          : undefined,
        Incubation: newProjectForm.getFieldValue("incubation")
          ? dayjs(newProjectForm.getFieldValue("incubation")).toISOString()
          : undefined,
        ViableBusiness: newProjectForm.getFieldValue("viableBusiness")
          ? dayjs(newProjectForm.getFieldValue("viableBusiness")).toISOString()
          : undefined,
        ScaleSaling: newProjectForm.getFieldValue("scaleSaling")
          ? dayjs(newProjectForm.getFieldValue("scaleSaling")).toISOString()
          : undefined,
        Archived: newProjectForm.getFieldValue("archived")
          ? dayjs(newProjectForm.getFieldValue("archived")).toISOString()
          : undefined,
      };
      const newProject: ProjectFormDto = {
        id: newProjectForm.getFieldValue("id"),
        name: newProjectForm.getFieldValue("name"),
        stackholders: newProjectForm.getFieldValue("stackholders"),
        startDate: dayjs(
          newProjectForm.getFieldValue("startDate")
        ).toISOString(),
        endDate: newProjectForm.getFieldValue("endDate")
          ? dayjs(newProjectForm.getFieldValue("endDate")).toISOString()
          : undefined,
        status,
      };
      retryPromise(() => ProjectService.createProject(newProject), 5, 5000)
        .then(() => {
          setConfirmCreateProjectLoading(false);
          message.info(t("message.createProjectSuccessful"));
          updateProjectDto();
          setIsCreateProjectModalOpen(false);
          newProjectForm.resetFields();
        })
        .catch((err) => {
          setConfirmCreateProjectLoading(false);
          console.error(`update project fail ${err}`);
          message.error(t("error.unknown"));
          setIsCreateProjectModalOpen(false);
          newProjectForm.resetFields();
        });
    } catch (err: any) {
      console.error(`get err ${JSON.stringify(err)}`);
    }
  };

  const handleCancelCreateProject = () => {
    setIsCreateProjectModalOpen(false);
  };

  const defaultColumns = [
    {
      title: t("table.projectCode"),
      dataIndex: "id",
      key: "projectCode",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("table.projectName"),
      dataIndex: "name",
      key: "projectName",
      ellipsis: true,
      editable: true,
      width: 100,
    },
    {
      title: t("table.stackholders"),
      dataIndex: "stackholders",
      key: "stackholders",
      editable: true,
      width: 250,
      render: (stackholders: any) => {
        return (
          <>
            {stackholders && stackholders.length !== 0
              ? stackholders.map((stackholder: string, index: number) => (
                  <Tag color={Colors[index]} key={stackholder}>
                    {stackholder}
                  </Tag>
                ))
              : "--"}
          </>
        );
      },
    },
    {
      title: t("table.startDate"),
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,
      editable: true,
      width: 100,
      render: (data: string) => {
        const dataStr = convertUTCToLocalTime(data, "YYYY-MM-DD");
        return dataStr === "" ? "--" : dataStr;
      },
    },
    {
      title: t("table.endDate"),
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,
      editable: true,
      width: 100,
      render: (data: string) => {
        const dataStr = convertUTCToLocalTime(data, "YYYY-MM-DD");
        return dataStr === "" ? "--" : dataStr;
      },
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (data: ProjectStatus) => {
        if (data.Archived) {
          return <Tag color="warning">{t("tag.archived")}</Tag>;
        }
        if (
          data.Conceptualization &&
          moment(data.Conceptualization).isBefore(moment.now())
        ) {
          return <Tag color="success">{t("tag.finished")}</Tag>;
        }
        return <Tag color="processing">{t("tag.inProcess")}</Tag>;
      },
    },
    {
      title: t("table.action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_: any, record: ProjectFormDto) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Popconfirm
              title="Are you sure you want to save the changes?"
              onConfirm={() => handleSave(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="save-button"
                type="primary"
                size="small"
                disabled={!roles.includes("admin")}
                icon={<SaveOutlined />}
              >
                {t("btn.save")}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to discard the changes?"
              onConfirm={() => handleClose(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="delete-button"
                type="primary"
                size="small"
                disabled={!roles.includes("admin")}
                danger
                icon={<CloseCircleOutlined />}
              >
                {t("btn.cancel")}
              </Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button
              className="edit-button"
              type="primary"
              size="small"
              disabled={!roles.includes("admin")}
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              {t("btn.edit")}
            </Button>
          </Space>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProjectFormDto) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editableCell: React.FC<
    EditableCellProps<ProjectFormDto | ProjectExpandedDataType>
  > = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "startDate" ||
      dataIndex === "endDate" ||
      dataIndex === "phaseEndDate" ? (
        <DatePicker
          locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
          defaultValue={
            convertUTCToLocalTime(
              lodash.get(record, dataIndex) ?? "invalid",
              "YYYY-MM-DD"
            ) !== ""
              ? dayjs(
                  convertUTCToLocalTime(
                    lodash.get(record, dataIndex) ?? "invalid",
                    "YYYY-MM-DD"
                  ),
                  "YYYY-MM-DD"
                )
              : undefined
          }
          format="YYYY-MM-DD"
        />
      ) : dataIndex === "stackholders" ? (
        // Use input box for adding/editing stackholders
        <Space size={[0, 8]} wrap>
          <Space size={[0, 8]} wrap>
            {tags && tags.length !== 0
              ? tags.map((tag: string, index: number) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        className="stackholderInput"
                        ref={editInputRef}
                        key={tag}
                        size="small"
                        value={editInputValue}
                        defaultValue={tag}
                        onChange={handleTagEditInputChange}
                        onBlur={handleTagEditInputConfirm}
                        onPressEnter={handleTagEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={true}
                      style={{ userSelect: "none" }}
                      onClose={() => handleTagClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          e.preventDefault();
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })
              : "--"}
          </Space>
          {inputVisible ? (
            <Input
              ref={inputRef}
              className="stackholderInput"
              type="text"
              size="small"
              value={inputValue}
              defaultValue={""}
              onChange={handleTagInputChange}
              onBlur={handleTagInputConfirm}
              onPressEnter={handleTagInputConfirm}
            />
          ) : (
            <Tag
              style={{
                background: token.colorBgContainer,
                borderStyle: "dashed",
              }}
              onClick={showTagInput}
            >
              <PlusOutlined /> New Tag
            </Tag>
          )}
        </Space>
      ) : (
        <Input defaultValue={lodash.get(record, dataIndex)} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={
              dataIndex === "phaseEndDate"
                ? lodash.get(record, "phaseName")
                : dataIndex
            }
            style={{ margin: 0 }}
            rules={[
              ({ getFieldValue }) => ({
                validator(obj: any, value: any) {
                  if (
                    obj.field === "endDate" &&
                    moment(`${value}`, "ddd, DD MMM YYYY HH:mm:ss z").isBefore(
                      moment(
                        `${getFieldValue("startDate")}`,
                        "ddd, DD MMM YYYY HH:mm:ss z"
                      )
                    )
                  ) {
                    return Promise.reject("End Date must be after Start Date!");
                  }
                  if (obj.field === "startDate") {
                    if (value === null) {
                      return Promise.reject("Please enter start date!");
                    }
                    if (
                      moment(`${value}`, "ddd, DD MMM YYYY HH:mm:ss z").isAfter(
                        moment(
                          `${getFieldValue("endDate")}`,
                          "ddd, DD MMM YYYY HH:mm:ss z"
                        )
                      )
                    ) {
                      return Promise.reject(
                        "Start Date must be before End Date!"
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const expandedRowRender = (record: ProjectFormDto, index: number) => {
    const defaultStatusColumns = [
      { title: t("table.phaseName"), dataIndex: "phaseName", key: "phaseName" },
      {
        title: t("table.endDate"),
        editable: true,
        dataIndex: "phaseEndDate",
        key: "phaseEndDate",
        render: (date: string, record: any) => {
          if (date) {
            return date;
          }
          return "--";
        },
      },
      {
        title: t("table.status"),
        dataIndex: "phaseState",
        key: "phaseState",
        render: (state: PhaseState) => {
          let color: any = "default";
          if (state === PhaseState.InProgress) {
            color = "processing";
          }
          if (state === PhaseState.Finished) {
            color = "success";
          }
          if (state === PhaseState.Stopped) {
            color = "error";
          }
          if (state === PhaseState.Unknown) {
            color = "warning";
          }
          return <Badge status={color} text={t(`label.${state}`)} />;
        },
      },
    ];
    const columns = defaultStatusColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: ProjectExpandedDataType) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
    const phases = Object.keys(record.status);
    const currentTime = moment.now();
    const isArchived =
      record.status.Archived !== undefined &&
      moment(
        convertUTCToLocalTime(record.status.Archived, "YYYY-MM-DD")
      ).isBefore(currentTime);
    let inProgress = false;
    const data = phases.map<ProjectExpandedDataType>((phase: string) => {
      const date = convertUTCToLocalTime(
        lodash.get(record.status, phase),
        "YYYY-MM-DD"
      );
      let state = PhaseState.Planned;
      if (isArchived) {
        if (phase === "Archived") {
          state = PhaseState.Finished;
        } else if (moment(date).isBefore(currentTime)) {
          state = PhaseState.Finished;
        } else {
          state = PhaseState.Stopped;
        }
      } else if (date === "") {
        // no date defined
        if (inProgress) {
          state = PhaseState.Unknown;
        } else {
          state = PhaseState.InProgress;
          inProgress = true;
        }
      } else if (moment(date).isBefore(currentTime)) {
        // finish
        state = PhaseState.Finished;
      } else if (inProgress) {
        // planned or in progress
        state = PhaseState.Planned;
      } else {
        state = PhaseState.InProgress;
        inProgress = true;
      }
      return {
        id: record.id,
        phaseName: phase,
        phaseEndDate: date,
        phaseState: state,
      };
    });
    return (
      <Table
        components={{
          body: {
            cell: editableCell,
          },
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const updateProjectDto = () =>
    ProjectService.getProjects().then((result) => {
      setDataSource(result);
      setIsLoading(false);
    });

  useEffect(() => {
    updateProjectDto();
  }, []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Modal
        title={t("label.newProject")}
        open={isCreateProjectModalOpen}
        onOk={handleCreateProject}
        onCancel={handleCancelCreateProject}
        okText={t("btn.add")}
        cancelText={t("btn.cancel")}
        confirmLoading={confirmCreateProjectLoading}
      >
        <Form
          name="createProject"
          form={newProjectForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 800, marginRight: "50px" }}
          scrollToFirstError
          autoComplete="off"
        >
          <Form.Item
            label={t("table.projectCode")}
            name="id"
            style={{
              marginTop: "30px",
            }}
            rules={[
              {
                required: true,
                message: `${t("alert.mustInputProjectCode")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("table.projectName")}
            name="name"
            rules={[
              {
                required: true,
                message: `${t("alert.mustInputProjectName")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("table.startDate")}
            name="startDate"
            rules={[
              {
                required: true,
                message: `${t("alert.mustInputStartDate")}`,
              },
            ]}
          >
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item label={t("table.endDate")} name="endDate">
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Divider orientation="left">{t("label.phaseEndDate")}</Divider>
          <Form.Item label={t("label.ideation")} name="ideation">
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            label={t("label.conceptualization")}
            name="conceptualization"
          >
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item label={t("label.incubation")} name="incubation">
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item label={t("label.viableBusiness")} name="viableBusiness">
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item label={t("label.scaleSaling")} name="scaleSaling">
            <DatePicker
              locale={i18n.language === "zh-CN" ? zhlocale : enlocale}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        onClick={handleAddProject}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        {t("btn.addProject")}
      </Button>
      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          columns={columns}
          components={{
            body: {
              cell: editableCell,
            },
          }}
          dataSource={dataSource}
          rowKey={"id"}
          scroll={{ x: "max-content" }}
          rowClassName={() => "editable-row"}
          pagination={{
            pageSize,
            showTotal,
          }}
          expandable={{ expandedRowRender }}
        />
      </Form>
    </ConfigProvider>
  );
};

export default ProjectPage;
