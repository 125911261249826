import * as lodash from "lodash";

import { IDevice } from "../interfaces/device.interface";

export function getDevicePropertyValue<T>(
  propertyName: string,
  device: IDevice,
  defaultValue?: T,
): T {
  if (!lodash.get(device.properties ?? {}, propertyName)) {
    return defaultValue as T;
  }
  let result = defaultValue;
  const desiredValue = lodash.get(
    device.properties ?? {},
    propertyName,
  ).desiredValue;
  const reportedValue = lodash.get(
    device.properties ?? {},
    propertyName,
  ).reportedValue;
  if (desiredValue) {
    if (lodash.isArray(desiredValue)) {
      result = desiredValue as T;
    } else if (lodash.isObject(desiredValue)) {
      result = lodash.assign(result, desiredValue);
    } else {
      result = desiredValue;
    }
  }
  if (reportedValue) {
    if (lodash.isArray(reportedValue)) {
      result = reportedValue as T;
    } else if (lodash.isObject(reportedValue)) {
      result = lodash.assign(result, reportedValue);
    } else {
      result = reportedValue;
    }
  }
  return result as T;
}
