import moment from 'moment';
import BigNumber from 'bignumber.js';

export function validateTimeStamp(timestamp: string): string | undefined {
  const momentObj = moment.utc(timestamp, moment.ISO_8601, true);
  const isValid = momentObj.isValid();
  if (!isValid) {
    return undefined;
  }
  if (momentObj.utcOffset() !== 0) {
    return momentObj.utc().toISOString();
  } else {
    return momentObj.toISOString();
  }
}

export function validateTimeSerial(startTime: string, endTime: string): boolean {
  const momentObj1 = moment.utc(startTime, moment.ISO_8601, true);
  const momentObj2 = moment.utc(endTime, moment.ISO_8601, true);
  if (!momentObj1.isValid() || !momentObj2.isValid()) {
    return false;
  }
  const isValid = momentObj1.isBefore(momentObj2);
  if (!isValid) {
    return false;
  }
  return true;
}

export function durationMs(
  startTimestamp: string,
  endTimestamp: string,
): number {
  validateTimeSerial(startTimestamp, endTimestamp);
  const start = new Date(startTimestamp).getTime();
  const end = new Date(endTimestamp).getTime();
  const duration = end - start;
  return duration;
}

export function durationStr(
  startTimestamp: string,
  endTimestamp: string,
): string {
  const duration = durationMs(startTimestamp, endTimestamp);
  const year = 31536000000; // 1 year in milliseconds
  const day = 86400000; // 1 day in milliseconds
  const hour = 3600000; // 1 hour in milliseconds
  const minute = 60000; // 1 minute in milliseconds
  const second = 1000; // 1 second in milliseconds

  if (duration >= year) {
    const value = Math.floor(duration / year);
    return `${value} year${value > 1 ? 's' : ''}`;
  } else if (duration >= day) {
    const value = Math.floor(duration / day);
    return `${value} day${value > 1 ? 's' : ''}`;
  } else if (duration >= hour) {
    const value = Math.floor(duration / hour);
    return `${value} hour${value > 1 ? 's' : ''}`;
  } else if (duration >= minute) {
    const value = Math.floor(duration / minute);
    return `${value} minute${value > 1 ? 's' : ''}`;
  } else if (duration >= second) {
    const value = Math.floor(duration / second);
    return `${value} second${value > 1 ? 's' : ''}`;
  } else {
    return `${duration} mini-second(s)`;
  }
}

export function roundValue(value: number, round: number): number {
  const multiplier = 10 ** round;
  const multiplied = Math.round(value * multiplier);
  const rounded = Math.round(multiplied) / multiplier;

  if ((rounded * multiplier) % 10 >= 5) {
    return Number(
      BigNumber(((Math.floor(multiplied / 10) + 1) / (multiplier / 10))).toFixed(round),
    );
  } else {
    return Number(BigNumber(rounded).toFixed(round));
  }
}

export async function delay(milliseconds: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

export function captialFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getRandomItem(arr: any[]) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export function getLocalTimeString(utcTimeStamp: string, format: string = "YYYY/MM/DD HH:mm:ss"): string {
  const utcMoment = moment.utc(utcTimeStamp);
  const localMoment = utcMoment.local();
  const localTimeStr = localMoment.format(format);
  return localTimeStr;
}

export function getUTCTimeString(localtime: string): string {
  const localTimestamp = moment(localtime);

  if (!localTimestamp.isValid()) {
    return "";
  }

  const utcTimestamp = localTimestamp.utc().toISOString();
  return utcTimestamp;
}

export function getTimeStampDiffMs(earlyISOTimestamp: string, laterISOTimestamp: string): number {
  earlyISOTimestamp = moment(new Date(earlyISOTimestamp)).toISOString();
  laterISOTimestamp = moment(new Date(laterISOTimestamp)).toISOString();
  if (earlyISOTimestamp === getExtremeTimestamp([earlyISOTimestamp, laterISOTimestamp], true)) {
    return moment(laterISOTimestamp).diff(moment(earlyISOTimestamp), "milliseconds");
  }
  return -1;
}

export function getExtremeTimestamp(timestamps: string[], isEarliest: boolean): string | undefined {
  if (timestamps.length === 0) {
    return undefined;
  }

  const validTimestamps: string[] = [];

  for (const timestamp of timestamps) {
    if (moment(timestamp, moment.ISO_8601, true).isValid()) {
      validTimestamps.push(timestamp);
    } else {
      console.warn(`Input timestamp ${timestamp} is not valid ISO 8601 format.`);
    }
  }

  if (isEarliest) {
    const earliestMoment: moment.Moment = moment.min(validTimestamps.map(ts => moment(ts)));
    return earliestMoment.toISOString();
  } else {
    const latestMoment: moment.Moment = moment.max(validTimestamps.map(ts => moment(ts)));
    return latestMoment.toISOString();
  }
}

export async function retryPromise<T>(promise: () => Promise<T>, maxRetries: number, interval: number): Promise<T> {
  let retries = 0;
  while (retries <= maxRetries) {
    try {
      const result = await promise();
      return result;
    } catch (err) {
      retries++;
      await new Promise(resolve => setTimeout(resolve, interval));
      console.log(`Promise execution failed. Retrying... (${retries}/${maxRetries})`);
    }
  }
  throw new Error(`Failed after ${retries} retries`);
}

export function convertByteToReadableVal(byte: string): string {
  const bigBytes = parseFloat(byte);
  // larger than 1TB
  if (bigBytes >= 1024 * 1024 * 1024 * 1024) {
    return `${roundValue(bigBytes / (1024 * 1024 * 1024 * 1024), 3)} TB`
  }
  // larger than 1GB
  if (bigBytes >= 1024 * 1024 * 1024) {
    return `${roundValue(bigBytes / (1024 * 1024 * 1024), 3)} GB`
  }
  // larger than 1MB
  if (bigBytes >= 1024 * 1024) {
    return `${roundValue(bigBytes / (1024 * 1024), 3)} MB`
  }
  // larger than 1KB
  if (bigBytes >= 1024) {
    return `${roundValue(bigBytes / (1024), 3)} KB`
  }
  return `${bigBytes} Bytes`
}
      



