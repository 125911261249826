import axios from "axios";
import moment from "moment";

import {
  IRealmRole,
  IUser,
  IUserCreation,
  IUserData,
  IUserInfo,
  IUserUpdate,
} from "../interfaces/user.interface";

class UserService {
  private _roleList: IRealmRole[] = [];
  private readonly _buildInRoles = [
    "offline_access",
    "uma_authorization",
    "default-roles-p2playground",
  ];

  public userProfile: IUserInfo | undefined;

  async createUser(account: IUserCreation): Promise<void> {
    await axios.post("/users", account, { headers: {"content-type": "application/json"} });
    // there is a bug in keycloak about assigning role to user when creating
    // ref: https://stackoverflow.com/questions/57390389/the-realmroles-parameter-is-ignored-when-adding-a-user-via-the-keycloak-api
    // workaround: new request to assign role if the new user is not default role: user
    if (account.realmRoles && account.realmRoles[0] !== "user") {
      const users = await this.readUserList(true);
      const user = users.filter((data) => data.email === account.email)[0];
      if (user) {
        for (const item of this._roleList) {
          if (account.realmRoles[0] === item.name) {
            await axios.post(`/users/${user.id}/role-mappings/realm`, [item], { headers: {"content-type": "application/json"} });
          }
        }
      }
    }
  }

  async readUser(): Promise<IUserInfo> {
    const res = await axios.get<IUserInfo>("/userinfo", { headers: {"content-type": "application/json"} });
    this.userProfile = res.data;
    return this.userProfile;
  }

  async readUserList(noRoles = false): Promise<IUserData[]> {
    await this._getAllRealmRoles();
    const res = await axios.get<IUser[]>("/users", { headers: {"content-type": "application/json"} });
    const users = res.data.filter((data) => data.email);
    const userList = users.map<IUserData>((user: IUser) => {
      return {
        id: user.id,
        createdTimestamp: moment(user.createdTimestamp).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        firstName: user.firstName,
        lastName: user.lastName,
        enabled: user.enabled,
        roles: [],
        email: user.email,
        emailVerified: user.emailVerified,
        gender: user.attributes.gender[0],
        organization: user.attributes.organization[0],
        phoneNation: user.attributes.phoneNation[0],
        phoneNr: user.attributes.phoneNr[0],
        requiredActions: user.requiredActions,
      };
    });
    if (!noRoles) {
      // get role of each user
      for (let user of userList) {
        const resRoles = await axios.get(
          `/users/${user.id}/role-mappings/realm`,
          { headers: {"content-type": "application/json"} }
        );
        user.roles = resRoles.data.map((data: IRealmRole) => {
          if (data.name === "default-roles-p2playground") {
            return "user";
          } else {
            return data.name;
          }
        });
        if (user.roles.length > 1) {
          user.roles = user.roles.filter((data) => data !== "user");
        }
      }
    }
    return userList;
  }

  async updateUser(id: string, data: IUserUpdate): Promise<void> {
    await axios.put(`/users/${id}`, data, { headers: {"content-type": "application/json"} });
  }

  async deleteUser(id: string): Promise<void> {
    await axios.delete(`/users/${id}`, { headers: {"content-type": "application/json"} });
  }

  async assignRole(id: string, roleName: string): Promise<void> {
    const users = await this.readUserList(true);
    const user = users.filter((data) => data.id === id)[0];
    if (user) {
      for (const item of Array.from(this._roleList.values())) {
        if (roleName === item.name) {
          await axios.post(`/users/${id}/role-mappings/realm`, [item], { headers: {"content-type": "application/json"} });
        }
      }
    } else {
      throw new Error(
        `this user ${id} is not found for assigning role ${roleName}`
      );
    }
  }

  async activeUser(id: string): Promise<void> {
    await axios.put(`/users/${id}`, {
      enabled: true,
    }, { headers: {"content-type": "application/json"} });
  }

  async disableUser(id: string): Promise<void> {
    await axios.put(`/users/${id}`, {
      enabled: false,
    }, { headers: {"content-type": "application/json"} });
  }

  async sendActiveLetter(id: string): Promise<void> {
    await axios.put(`/users/${id}/send-verify-email`, ["VERIFY_EMAIL"], { headers: {"content-type": "application/json"} });
  }

  async sendResetPass(id: string): Promise<void> {
    await axios.put(`/users/${id}/execute-actions-email`, ["UPDATE_PASSWORD"], { headers: {"content-type": "application/json"} });
  }

  private async _getAllRealmRoles(): Promise<void> {
    const res = await axios.get("/roles", { headers: {"content-type": "application/json"} });
    this._roleList = res.data.filter(
      (data: IRealmRole) => !this._buildInRoles.includes(data.name)
    );
  }
}

const userService = new UserService();
export default userService;
