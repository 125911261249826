import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
  theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import "../styles/diagram.scss";

import TelemetryService from "../services/telemetry.service";
import DeviceTwinService from "../services/device.service";
import dayjs from "../utils/dayjs";
import Diagram, { DiagramProps } from "../components/diagram.component";

import { getSelectOptions } from "../utils/ui";

const DiagramPage: React.FC = () => {
  const startTime = dayjs().add(-1, "day");
  const endTime = dayjs();
  const chartTypeList: ("line" | "heatMap")[] = ["line"];

  const { darkAlgorithm } = theme;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Paragraph } = Typography;
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const [form] = Form.useForm();

  const [telemetryList, setTelemetryList] = useState<string[] | undefined>(
    undefined
  );
  const [idList, setIdList] = useState<string[] | undefined>(undefined);
  const [chartParams, setChartParams] = useState<DiagramProps | undefined>(undefined);
  const [chartType, setChartType] = useState(chartTypeList[0]);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const handleChartUpdate = (values: any) => {
    //{"ids":["rainuse-turbidity-effect-test"],"telemetry":"turbidity","chartType":"line","dateRange":["2023-11-30T07:40:55.274Z","2023-12-01T07:40:55.275Z"]}
    form.validateFields().then(() => {
      setChartParams({
        type: values.chartType,
        ids: values.ids,
        telemetry: values.telemetry,
        startDate: values.dateRange[0],
        endDate: values.dateRange[1]
      })
    })
  };

  //#region get id list
  useEffect(() => {
    if (!id) {
      message.error(t("error.noValidId"));
      navigate("/");
      return;
    }
    // get device componients
    DeviceTwinService.getDevice(id)
      .then((device) => {
        let components = device.componients;
        if (!device.componients || device.componients.length === 0) {
          components = [id];
        }
        if (components.length !== 1) {
          components = components.map((item) => {
            return device.id + "-" + item;
          });
        }
        setIdList(components);
        // console.info(`Get id list ${JSON.stringify(components)}`);
      })
      .catch((err) => {
        message.error(t("error.network error"));
        console.error(err);
      });
  }, []);
  //#endregion

  //#region set selected id when id list updated
  useEffect(() => {
    if (!id) {
      message.error(t("error.noValidId"));
      navigate("/");
      return;
    }
    if (!idList || idList.length === 0) {
      return;
    }
    setSelectedId(idList[0]);
    // console.info(`Get selected id list ${JSON.stringify(idList[0])}`);
  }, [idList, t]);
  //#endregion

  //#region get telemetry list based on id list
  useEffect(() => {
    if (!id) {
      message.error(t("error.noValidId"));
      navigate("/");
      return;
    }
    if (!selectedId) {
      return;
    }
    TelemetryService.getTelemetryList(selectedId)
      .then((result) => {
        setTelemetryList(result);
        // console.info(`Get telemetry list ${JSON.stringify(result)}`);
      })
      .catch((err) => {
        message.error(t("error.network error"));
        console.error(err);
      });
  }, [selectedId]);
  //#endregion

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
        }}
      >
        {idList === undefined ||
        selectedId === undefined ||
        telemetryList === undefined ? (
          <>
            <Spin
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              spinning={true}
            />
          </>
        ) : (
          <>
            <Layout style={{ minHeight: "100vh" }}>
              <Sider
                theme="light"
                style={{
                  overflow: "auto",
                  height: "100vh",
                  position: "fixed",
                  left: 0,
                  top: 0,
                  bottom: 0,
                }}
                width={350}
              >
                <Paragraph copyable className="chartIdTitle">
                  {id}
                </Paragraph>

                <Form
                  className="chartMenu"
                  form={form}
                  layout="vertical"
                  initialValues={{
                    ids: [idList[0]],
                    telemetry: telemetryList[0],
                    chartType: chartTypeList[0],
                    dateRange: [startTime, endTime],
                  }}
                  onFinish={handleChartUpdate}
                >
                  <Form.Item name="ids" label={t("label.deviceIds")}>
                    <Select
                      placeholder={t("select.pleaseSelectIds")}
                      options={getSelectOptions(idList)}
                    />
                  </Form.Item>
                  <Form.Item name="telemetry" label={t("label.telemetryType")}>
                    <Select
                      options={telemetryList.map((telemetry: string) => {
                        return {
                          label: t(`telemetry.${telemetry}`),
                          value: telemetry,
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item name="chartType" label={t("label.chartType")}>
                    <Select
                      options={chartTypeList.map((chartType: string) => {
                        return {
                          label: t(`chart.${chartType}`),
                          value: chartType,
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item name="dateRange" label={t("label.dateRange")} rules={[{ type: 'array' as const, required: true, message: 'Please select time!' }]}>
                    <RangePicker
                      showTime={{ format: "HH:mm:ss" }}
                      format="YYYY MM/DD HH:mm:ss"
                      allowEmpty={[false, false]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                      {t("btn.ok")}
                    </Button>
                  </Form.Item>
                </Form>
              </Sider>
              <Layout>
              <Content style={{ margin: "16px 16px 0 360px" }}>
                <Row gutter={[16, 24]}>
                  <Col className="gutter-row" span={24}>
                    <Card title={t(chartType)} className="diagramCard">
                        {chartParams ? (
                          <Diagram
                            type={chartParams.type}
                            ids={chartParams.ids}
                            telemetry={chartParams.telemetry}
                            startDate={chartParams.startDate}
                            endDate={chartParams.endDate}
                          ></Diagram>
                        ) : (
                          <></>
                        )}
                    </Card>
                  </Col>
                </Row>
              </Content>
              </Layout>
            </Layout>
          </>
        )}
      </ConfigProvider>
    </>
  );
};

export default DiagramPage;
