import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { Empty, Spin, message } from "antd";

import TelemetryService from "../services/telemetry.service";
import dayjs from "../utils/dayjs";

import { getLocalTimeString } from "../utils/util";
import { ITelemetry } from "../interfaces/telemetry.interface";

export interface DiagramProps {
  type: "line" | "heatMap";
  ids: string[];
  telemetry: string;
  startDate: string;
  endDate: string;
}

function groupTelemetryByDay(telemetryData: ITelemetry[]): [string, number][] {
  const telemetryByDayMap = new Map<string, number>();

  telemetryData.forEach((telemetry) => {
    const dateStr = getLocalTimeString(telemetry.timestamp, "YYYY-MM-DD");
    if (telemetryByDayMap.has(dateStr)) {
      telemetryByDayMap.set(dateStr, telemetryByDayMap.get(dateStr)! + 1);
    } else {
      telemetryByDayMap.set(dateStr, 1);
    }
  });

  const telemetryByDayArr: [string, number][] = [];
  telemetryByDayMap.forEach((value, key) => {
    telemetryByDayArr.push([key, value]);
  });
  return telemetryByDayArr;
}

const Diagram = ({
  type,
  ids,
  telemetry,
  startDate,
  endDate,
}: DiagramProps) => {
  const [options, setOptions] = useState<any>(undefined);
  const [telemetryDto, setTelemetryDto] = useState<ITelemetry[][] | undefined>(
    undefined
  );
  const [isChartsLoading, setIsChartsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const { t } = useTranslation();

  const loadingOption = {
    text: t("label.loading"),
    textColor: "#270240",
    maskColor: "rgba(255, 255, 255, 0)",
    zlevel: 0,
  };

  useEffect(() => {
    setIsChartsLoading(true);
    Promise.all(
      ids.map(async (id: string) => {
        const data = await TelemetryService.getTimeserialDto(
          id,
          telemetry,
          dayjs(startDate).toISOString(),
          dayjs(endDate).toISOString(),
          false
        );
        if (data.length === 0) {
          return [];
        }
        setIsEmpty(false);
        return data;
      })
    )
      .then((result) => {
        setTelemetryDto(result);
      })
      .catch((err) => {
        console.error(err);
        message.error(t("error.unknown"));
        setIsChartsLoading(false);
        setIsEmpty(true);
      });
  }, [ids, telemetry, startDate, endDate, t]);

  useEffect(() => {
    if (!telemetryDto || !telemetryDto[0]) {
      setIsChartsLoading(false);
      setIsEmpty(true);
      return;
    }
    if (type === "line") {
      setOptions({
        backgroundColor: "#141414",
        xAxis: {
          type: "time",
          name: "Time",
          axisLabel: {
            formatter: (value: string) => {
              return getLocalTimeString(value, "YYYY/MM/DD HH:mm:ss");
            },
          },
        },
        yAxis: {
          type: "value",
          name: telemetryDto[0].filter((item) => item.unit)[0]?.unit,
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 20,
          },
          {
            start: 0,
            end: 20,
          },
        ],
        legend: {
          data: telemetryDto
            .map((item) => {
              if (item.length === 0) {
                return undefined;
              } else {
                return item[0].id;
              }
            })
            .filter((item) => item !== undefined),
        },
        tooltip: {
          trigger: "axis",
        },
        series: telemetryDto.map((item) => {
          if (item.length === 0) {
            return;
          }
          return {
            name: item[0].id,
            type: "line",
            data: item.map((dto) => {
              return [dto.timestamp, dto.value];
            }),
            smooth: true,
            markPoint: {
              data: [
                {
                  type: "max",
                  name: "max",
                },
                {
                  type: "min",
                  name: "min",
                },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          };
        }),
      });
      setIsChartsLoading(false);
    }
    if (type === "heatMap") {
      setOptions({
        backgroundColor: "#141414",
        tooltip: {
          formatter: (params: any) => {
            //{"componentType":"series","componentSubType":"heatmap","componentIndex":0,"seriesType":"heatmap",
            //"seriesIndex":0,"seriesId":"\u0000series\u00000\u00000","seriesName":"series\u00000",
            //"name":"","dataIndex":1,"data":["2023-11-02",2730],"value":["2023-11-02",2730],
            //"color":"rgba(246,239,166,1)","dimensionNames":["time","value"],
            //"encode":{"time":[0],"value":[1]},"$vars":["seriesName","name","value"],
            //"marker":"<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgba(246,239,166,1);\"></span>"}
            // console.info(`check tooltip params ${JSON.stringify(params)}`)
            return `${t("label.date")}: ${params.data[0]}</br>${t(
              "label.nrOfData"
            )}: <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
              params.color
            };"></span>${params.data[1]}`;
          },
        },
        visualMap: {
          type: "piecewise",
          orient: "horizontal",
          left: "center",
          pieces: [
            { gt: 86400 }, // more than 1s per data (impossiable in normal)
            { gt: 43200, lte: 86400 }, // 2s per data - 1s per data
            { gt: 17280, lte: 43200 }, // 5s per data - 2s per data
            { gt: 310, lte: 17200 }, // 10s per data - 5s per data
            { gt: 200, lte: 300 }, //  30s per data - 10s per data
            { gt: 1, lte: 1 }, // 1 min per data - 30s per data
            { gt: 48, lte: 1440 }, // 30 min per data - 1 min per data
            { gte: 1, lte: 48 }, // less than 30 min per data
          ],
          top: 1,
        },
        calendar: {
          left: 30,
          right: 30,
          cellSize: "auto",
          range: [
            dayjs(startDate).format("YYYY-MM"),
            dayjs(endDate).format("YYYY-MM-DD"),
          ],
          itemStyle: {
            borderWidth: 2,
          },
          yearLabel: { show: true },
        },
        series: {
          type: "heatmap",
          coordinateSystem: "calendar",
          data: groupTelemetryByDay(telemetryDto[0]),
        },
      });
      setIsChartsLoading(false);
    }
  }, [type, telemetryDto]);

  return isEmpty === true ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ) : options === undefined ? (
    <Spin
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spinning={true}
    />
  ) : (
    <ReactECharts
      theme={"dark"}
      notMerge={true}
      option={options}
      showLoading={options === undefined ? true : isChartsLoading}
      loadingOption={loadingOption}
    ></ReactECharts>
  );
};

export default Diagram;
