import React, { useState } from "react";
import { ConfigProvider, theme, Card, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import "../styles/main.scss";

const MainPage: React.FC = () => {
  const { darkAlgorithm } = theme;
  const { Meta } = Card;
  const { t } = useTranslation();

  const openProjectDashboard = (projectNr: string) => {
    window.open(`/${projectNr}/`, "_blank");
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col className="gutter-row" span={6}>
          <Card
            className="guideCard"
            hoverable
            onClick={() => openProjectDashboard("sp")}
            cover={<img alt="example" height={350} src="../smartadapter.png" />}
          >
            <Meta
              title="Smart Adapter"
              description={t("title.sp")}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card
            className="guideCard"
            hoverable
            onClick={() => openProjectDashboard("rws")}
            cover={<img alt="example" height={350} src="../rain-use.png" />}
          >
            <Meta title="Rain Use" description={t("title.rws")} />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card
            className="guideCard"
            hoverable
            onClick={() => openProjectDashboard("gfs")}
            cover={
              <img
                alt="example"
                height={350}
                src="../grundFLUSH.png"
              />
            }
          >
            <Meta title="GrundFLUSH" description={t("title.gfs")} />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card
            className="guideCard"
            hoverable
            // onClick={() => openProjectDashboard("gfs")}
            cover={
              <img
                alt="example"
                height={350}
                src="../lora.png"
              />
            }
          >
            <Meta title="X2P Lora" description={t("title.lora")} />
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default MainPage;
