import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Row,
  Select,
  theme,
} from "antd";

import "../styles/setting.scss";

const SettingPage: React.FC = () => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const { darkAlgorithm } = theme;

  const [newLanguage, setNewLanguage] = useState(i18n.language);

  const handleLanguageChange = () => {
    // Toggle between CN and EN
    const newLanguage = i18n.language === "en-US" ? "zh-CN" : "en-US";
    setNewLanguage(newLanguage);
  };
  
  const handleSave = async (val: any) => {
    try {
      await form.validateFields(); // Make sure to validate fields before submitting
      await i18n.changeLanguage(newLanguage); // Change language first
      // console.info(`save setting`, val);
    } catch (error) {
      console.error("Error saving setting:", error);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col className="gutter-row" span={24}>
          <Card className="sysColumn" bordered={false}>
            <Form form={form} onFinish={handleSave}>
              <span>
                <Form.Item
                  name="lang"
                  label={t("lang")}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select system language"
                    onChange={handleLanguageChange}
                    defaultValue={i18n.language}
                    options={[
                      { value: "zh-CN", label: t("lang.CN") },
                      { value: "en-US", label: t("lang.US") },
                    ]}
                  />
                </Form.Item>
              </span>

              <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
                <Button className="saveBtn" type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  className="resetBtn"
                  htmlType="button"
                  onClick={onReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default SettingPage;
