import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Result,
  Select,
  message,
  theme,
} from "antd";
import { useNavigate } from "react-router-dom";

import "../styles/register.scss";

import userService from "../services/user.service";
import authService from "../services/auth.service";

import { Gender, IUserCreation, UserGroup } from "../interfaces/user.interface";
import { getErrorMsgKey } from "../utils/ui";
import { adminAccount } from "../utils/constants";

const RegisterPage: React.FC = () => {
  const { darkAlgorithm } = theme;

  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const [form] = Form.useForm();

  const [isAgreementModalOpen, setisAgreementModalOpen] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const handleReadAgreement = () => {
    setisAgreementModalOpen(false);
  };

  const handleRegister = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const newUser: IUserCreation = {
          emailVerified: false,
          enabled: true,
          firstName: form.getFieldValue("firstName"),
          lastName: form.getFieldValue("lastName"),
          username: `${form.getFieldValue("firstName")} ${form.getFieldValue(
            "lastName"
          )}`,
          email: form.getFieldValue("email"),
          attributes: {
            phoneNation: form.getFieldValue("prefixPhone"),
            phoneNr: form.getFieldValue("phone"),
            gender: form.getFieldValue("gender"),
            organization: form.getFieldValue("organization"),
          },
          credentials: [
            {
              type: "password",
              value: form.getFieldValue("confirmPass"),
              temporary: false,
            },
          ],
        };
        // console.info(`Register: check new user ${JSON.stringify(newUser)}`);
        authService
          .login(adminAccount)
          .then(() => {
            // console.info(`Register: login in with admin account for auth`);
            userService.createUser(newUser).then(() => {
              // console.info(`Register: new user created`);
              userService.readUserList(true).then((list) => {
                const newUser = list.find(
                  (user) => user.email === form.getFieldValue("email")
                );
                if (!newUser) {
                  message.error(`${t("error.UserNotFound")}`);
                  setLoading(false);
                  return;
                }
                userService.sendActiveLetter(newUser.id).finally(() => {
                  // console.info(`activation letter sent`);
                  setLoading(false);
                  setRegistered(true);
                  authService.logout().then(() => {
                    setTimeout(() => {
                      navigate("/");
                    }, 3000);
                  });
                });
                
              }).catch((error: any) => {
                console.error(`check error ${error}`)
                message.error(t(`error.${error.errorMessage}`))
              });
            }).catch((error: any) => {
              message.error(t(`error.${error.response.data.errorMessage}`))
            });;
          });
      })
      .catch((err) => {
        message.error(t(`error.${getErrorMsgKey(err)}`));
      });
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      {registered ? (
        <>
          <Result
            className="result"
            status="success"
            title={t("label.registerSuccessInfo")}
            subTitle={t("label.registerSuccessMsg")}
            extra={
              [
                // <Button type="primary">
                //   Go to Dashboard
                // </Button>,
                // <Button type="default">Back to Login</Button>,
              ]
            }
          />
        </>
      ) : (
        <>
          <Modal
            title={t("register.newUser")}
            open={isAgreementModalOpen}
            onOk={handleReadAgreement}
            okText={t("btn.readAgreement")}
          ></Modal>
          <Card
            className="registerCard"
            title={t("register.newUser")}
            extra={
              <Select
                style={{ width: 170 }}
                defaultValue={i18n.language}
                onChange={(val) => handleLanguageChange(val)}
              >
                <Select.Option value="zh-CN">{t("lang.CN")}</Select.Option>
                <Select.Option value="en-US">{t("lang.US")}</Select.Option>
              </Select>
            }
          >
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              form={form}
              name="register"
              onFinish={handleRegister}
              initialValues={{
                gender: Gender.Male,
                role: UserGroup.User,
                prefixPhone: "+86",
              }}
              style={{ maxWidth: 500 }}
              scrollToFirstError
            >
              <Form.Item
                label={t("register.firstNameLab")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputFirstNameAlert")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("register.lastNameLab")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputLastNameAlert")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="gender" label={t("register.genderLab")}>
                <Select>
                  <Select.Option value={Gender.Male}>
                    {t("register.maleLab")}
                  </Select.Option>
                  <Select.Option value={Gender.Female}>
                    {t("register.femaleLab")}
                  </Select.Option>
                  <Select.Option value={Gender.Other}>
                    {t("register.otherLab")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={t("register.passLab")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputpasswordAlert")}`,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPass"
                label={t("register.confirmPassLab")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputpasswordAlert")}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`${t("register.inputpasswordConfirmAlert")}`)
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="email"
                label={t("register.emailLab")}
                rules={[
                  {
                    type: "email",
                    message: `${t("register.inputInvalidEmailAlert")}`,
                  },
                  {
                    required: true,
                    message: `${t("register.inputEmailAlert")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="organization"
                label={t("register.addressLab")}
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputCompanyAlert")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label={t("register.phoneLab")}
                rules={[
                  {
                    required: true,
                    message: `${t("register.inputPhoneAlert")}`,
                  },
                ]}
              >
                <Input
                  addonBefore={
                    <Form.Item name="prefixPhone" noStyle>
                      <Select style={{ width: 70 }}>
                        <Select.Option value="+86">+86</Select.Option>
                        <Select.Option value="+45">+45</Select.Option>
                      </Select>
                    </Form.Item>
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(`${t("register.acceptAgreementAlert")}`)
                          ),
                  },
                ]}
                wrapperCol={{
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                }}
              >
                <Checkbox className="agreementCheckbox">
                  {t("register.agreement")}
                  <Button className="agreementbtn" type="link">
                    {t("register.agreementBtn")}
                  </Button>
                </Checkbox>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                }}
              >
                <Button
                  style={{ marginTop: "10px" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("register.registerButt")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </>
      )}
    </ConfigProvider>
  );
};

export default RegisterPage;
