import axios from "axios";
import moment from "moment";
import * as lodash from "lodash";
import jwt_decode from "jwt-decode";

import { Subscription, interval, retry, skip, take } from "rxjs";

import { ILoginRes, IUserLogin } from "../interfaces/auth.interface";
import { KeycloakRoles, roleAuth } from "../utils/constants";
import { IUserInfo } from "../interfaces/user.interface";

class AuthService {
  private _token: ILoginRes | undefined;
  private _refreshIntervalMs = 180000;
  private _refreshInterval: Subscription | undefined;

  /**
   *
   */
  constructor() {
    this._token = this._loadLocalToken();
  }

  get token(): ILoginRes | undefined {
    this._token = this._token ?? this._loadLocalToken();
    return this._token;
  }

  /**
   * login using Keycloak API
   * @param userAccount username (email) and password
   * @return
   * username or password is incorrect
   * 401:
   * {
   *   "error": "invalid_grant",
   *   "error_description": "Invalid user credentials"
   * }
   */
  async login(userAccount: IUserLogin): Promise<void> {
    const params = new URLSearchParams();
    params.append("client_id", "portal");
    params.append("grant_type", "password");
    params.append("username", userAccount.username);
    params.append("password", userAccount.password);
    params.append("scope", "openid");
    const res = await axios.post<ILoginRes>("/token", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    this._token = res.data;
    this._storeLocalToken();
    this._refreshIntervalMs = this._getRefreshIntervalMs(this._token.expires_in);
    const now = moment();
    const refresh = moment();
    const nowTimeStr = now.format("YYYY-MM-DD HH:mm:ss");
    const expiredTime = now.add(this._token.expires_in, "seconds");
    const refreshTime = refresh.add(this._refreshIntervalMs, "milliseconds");
    const expiredTimeStr = expiredTime.format("YYYY-MM-DD HH:mm:ss");
    const refreshTimeStr = refreshTime.format("YYYY-MM-DD HH:mm:ss");
    console.info(
      `login finished with token ${JSON.stringify(
        this._token
      )} at ${nowTimeStr}`
    );
    console.info(`Login: login token will expired at ${expiredTimeStr}, the next refresh will happen at ${refreshTimeStr}`)
    this._startRefreshToken();
  }

  // TODO realize 3rd party login without register new account
  async weChatLogin(): Promise<void> {}

  /**
   * refresh access token and refresh token before expired
   * @return
   * refresh is incorrect or expired
   * 400:
   * {
   *   "error": "invalid_grant",
   *   "error_description": "Invalid refresh token"
   * }
   */
  async logout(): Promise<void> {
    this._stopRefreshToken();
    if (!this._token) {
      return;
    }
    const params = new URLSearchParams();
    params.append("client_id", "portal");
    params.append("refresh_token", this._token.refresh_token);
    const res = await axios.post<ILoginRes>("/logout", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    this._token = res.data;
    localStorage.removeItem("p2token");
    console.info(`logout at ${moment().format("YYYY-MM-DD HH:mm:ss")}`);
  }

  /**
   * check if token is valid or not
   * @returns boolean value if token is available or not
   */
  isLogined(): boolean {
    this._token = this._token ?? this._loadLocalToken();
    console.info(`isLogined: check token ${this._token?.access_token}`);
    if (this._token) {
      const expSec = lodash.get(
        jwt_decode(this._token.access_token) as any,
        "exp"
      );
      if (expSec) {
        const expirationDate = new Date(expSec * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          fractionalSecondDigits: 3, // Show milliseconds with 3 digits
        };

        if (!this._refreshInterval && expirationDate >= new Date()) {
          this._startRefreshToken(false);
          console.info(`restart refresh interval`);
          return true;
        }
        console.info(
          `isLogined: Token will expired at ${expirationDate
            .toLocaleString(undefined, options)
            .replace(",", "")}`
        );
        if (expirationDate < new Date()) {
          localStorage.removeItem("p2token");
          console.info(`isLogined: clean up invalid token`)
          return false;
        }
        return true;
      }
    }
    return false;
  }

  /**
   * get the value of user profile from access token
   * @returns string value of role name, undefined if no token
   */
  getUserInfo(): IUserInfo | undefined {
    if (!this._token) {
      return undefined;
    }
    const decoded = jwt_decode(this._token.access_token);
    return decoded as IUserInfo;
  }

  /**
   * get the value of role from access token
   * @returns string value of role name, undefined if no token
   */
  getRoles(): string[] | undefined {
    const profile = this.getUserInfo();
    if (!profile) {
      return undefined;
    }
    return profile.realm_access.roles.filter(
      (role: string) => !KeycloakRoles.includes(role)
    );
  }

  /**
   * check if token has auth to access
   * @param url url that what to check if it can be accessed
   * @returns boolean value if access is authorized or not
   */
  hasAuth(url: string): boolean {
    const roles = this.getUserInfo()?.realm_access.roles;
    if (!roles || roles.length <= 0) {
      return false;
    }
    let authList: string[] = [];
    for (const role in roles) {
      const urls = lodash.get(roleAuth, role);
      if (urls) {
        authList = authList.concat(...urls);
      }
    }

    if (!authList.includes("*") && !authList.includes(url)) {
      return false;
    }
    return true;
  }

  private _storeLocalToken(): void {
    if (this._token) {
      localStorage.setItem("p2token", JSON.stringify(this._token));
    }
  }

  private _loadLocalToken(): ILoginRes | undefined {
    const tokenStr = localStorage.getItem("p2token");
    if (tokenStr !== null && tokenStr !== "") {
      return JSON.parse(tokenStr) as ILoginRes;
    }
    return undefined;
  }

  private _startRefreshToken(skipFirst = true): void {
    if (!this._token) {
      console.info(`no local token found for refresh interval`);
      return;
    }

    const refreshIntervalMs = this._getRefreshIntervalMs(
      this._token.expires_in
    );
    if (refreshIntervalMs !== this._refreshIntervalMs) {
      console.info(
        `the expired time of token is changed from ${this._refreshIntervalMs} to ${refreshIntervalMs}`
      );
      this._refreshIntervalMs = refreshIntervalMs;
      if (this._refreshInterval) {
        this._stopRefreshToken();
      }
    }
    console.info(`start setup refresh interval`)

    this._refreshInterval = interval(this._refreshIntervalMs)
      .pipe(
        skip(skipFirst ? 1 : 0), // Skip the first emission or not
        take(3), // Maximum 3 retries
        retry({
          delay: () => interval(1000), // Delay 1 second before each retry
          count: 3, // Maximum 3 retries
        })
      )
      .subscribe(async () => {
        try {
          console.info(`start refresh token at ${moment().format("YYYY-MM-DD HH:mm:ss")}`);
          await this._refreshToken();
        } catch (error) {
          console.error(
            `get error while refreshing token after 3 retries: ${error}`
          );
          this._token = undefined;
          await this.logout();
        }
      });
  }

  private async _stopRefreshToken(): Promise<void> {
    if (!this._refreshInterval) {
      return;
    }
    this._refreshInterval.unsubscribe();
    this._refreshInterval = undefined;
    console.info(`stop refresh token`);
  }

  /**
   * refresh access token and refresh token before expired
   * @return
   * refresh is incorrect or expired
   * 401:
   * {
   *   "error": "invalid_grant",
   *   "error_description": "Token is not active"
   * }
   */
  private async _refreshToken(): Promise<void> {
    if (!this._token) {
      throw new Error("Login expired!");
    }
    const params = new URLSearchParams();
    params.append("client_id", "portal");
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", this._token.refresh_token);
    params.append("scope", "openid");
    try {
      const res = await axios.post<ILoginRes>("/token", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      this._token = res.data;
      this._storeLocalToken();
      const now = moment();
      const refresh = moment();
      const nowTimeStr = now.format("YYYY-MM-DD HH:mm:ss");
      const expiredTime = now.add(this._token.expires_in, "seconds");
      const refreshTime = refresh.add(this._refreshIntervalMs, "milliseconds");
      const expiredTimeStr = expiredTime.format("YYYY-MM-DD HH:mm:ss");
      const refreshTimeStr = refreshTime.format("YYYY-MM-DD HH:mm:ss");
      console.info(
        `token is refreshed at ${nowTimeStr} and will expire at ${expiredTimeStr}, next refresh will happen at ${refreshTimeStr}`
      );
      const refreshIntervalMs = this._getRefreshIntervalMs(
        this._token.expires_in
      );
      if (refreshIntervalMs !== this._refreshIntervalMs) {
        console.info(
          `the expired time of token is changed from ${this._refreshIntervalMs} to ${refreshIntervalMs}, restart refresh interval now`
        );
        this._refreshIntervalMs = refreshIntervalMs;
        this._stopRefreshToken();
        this._startRefreshToken(false);
      }
    } catch (error: any) {
      if (
        lodash.keys(error.response.data).includes("error_description") &&
        lodash.get(error.response.data, "error_description") ===
          "Token is not active"
      ) {
        this._token = undefined;
        localStorage.removeItem("p2token");
        console.info(`token is expired, remove invalid local token`);
      }
    }
  }

  private _getRefreshIntervalMs(expireTimeSec: number): number {
    if (expireTimeSec < 5) {
      throw new Error(
        "Token expire time is smaller than 5s, which is not allowed."
      );
    }
    const refreshInterval = expireTimeSec * 1000 * 0.6;
    console.info(
      `refresh interval is set to ${
        refreshInterval / 1000
      } seconds, token expired after ${expireTimeSec} seconds`
    );
    return refreshInterval;
  }
}

const authService = new AuthService();

export default authService;
