import { EmptyValue } from "../utils/constants";

//#region azure iot device twin
export enum AuthType {
  SelfSigned = 'selfSigned',
  SAS = 'sas',
  CASigned = 'caSigned'
}

export interface DeviceTwinPropertyState {
  desiredValue?: any
  reportedValue?: any
  desiredLastUpdatedTime?: string
  reportedLastUpdatedTime?: string
}

export interface x509Thumbprint {
  primary_thumbprint?: string
  secondary_thumbprint?: string
}

export interface IDevice {
  deviceId: string
  moduleId: string
  tags: Record<string, string> | null
  deviceEtag: string
  etag: string
  version: number
  connectionState: 'Connected' | 'Disconnected'
  lastActivityTime: string
  status: 'enabled' | 'disabled'
  statusReason?: string
  statusUpdatedTime?: string
  lastUpdateTime: string
  x509Thumbprint: x509Thumbprint
  authenticationType: AuthType
  properties?: Record<string, DeviceTwinPropertyState>
  cloudToDeviceMessageCount: number
}
//#endregion

//#region normal properties
export interface DeviceTwinInfo {
  projectCode?: string;
  name?: string;
  lat?: number;
  lnt?: number;
  location?: string; 
}

export interface DeviceTwinProperties {
  info?: DeviceTwinInfo;
  components?: string[]; 
}
//#endregion

//#region device twin gui
export enum DeviceTwinStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Online = 'online',
  Offline = 'offline',
  Warn = 'warn',
  Error = 'error'
}

export interface IDeviceTwin {
  id: string,
  name?: string,
  projectCode?: string;
  location?: string;
  tags: string[] | '--';
  componients: string[];
  status: DeviceTwinStatus;
}

export interface IDeviceRow extends IDeviceTwin {
  hasData: boolean;
}
