import React, { useEffect, useState } from "react";
import { useNavigate, Route, Routes, useLocation } from "react-router-dom";
import * as lodash from "lodash";
import Sider from "antd/es/layout/Sider";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  ConfigProvider,
  Layout,
  Menu,
  Modal,
  message,
  theme,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  DesktopOutlined,
  DashboardOutlined,
  AppstoreOutlined,
  ProjectOutlined,
  ExperimentOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import MainPage from "./main.page";
import DashboardPage from "./dashboard.page";
import DevicePage from "./devices.page";
import ProjectPage from "./project.page";
import SettingPage from "./setting.page";
import UserPage from "./user.page";

import authService from "../services/auth.service";
import ModulePage from "./module.page";

import "../styles/dashboard.scss";

import { AvatarIcoCor } from "../utils/constants";
import { MenuItem } from "../interfaces/ui.interface";
import { getErrorMsgKey, getMenuItem } from "../utils/ui";

const EntryPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const { darkAlgorithm } = theme;

  const handleMenuSelect = (key: string) => {
    // Update the URL when a menu item is clicked
    // console.info(`select key ${key}`);
    // Set the selectedKeys based on the current path
    if (key === "1") {
      navigate("/");
    } else if (key === "2") {
      navigate("/dashboard");
    } else if (key === "3") {
      navigate("/devicelist");
    } else if (key === "4") {
      navigate("/modules");
    } else if (key === "5") {
      navigate("/projects");
    } else if (key === "6") {
      navigate("/userlist");
    } else if (key === "7") {
      navigate("/settings");
    } else if (key === "8") {
      setIsLogoutModalOpen(true);
    }
  };

  const handleLogout = () => {
    setIsLogoutModalOpen(false);
    authService
      .logout()
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        message.error(t(`error.${getErrorMsgKey(err)}`));
      });
  };

  const handleCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const adminItems: MenuItem[] = [
    getMenuItem(
      <div>
        <Avatar
          style={{
            backgroundColor: authService
              .getUserInfo()!
              .realm_access.roles.includes("admin")
              ? lodash.get(AvatarIcoCor, "admin")
              : authService.getUserInfo()!.realm_access.roles.includes("user")
              ? lodash.get(AvatarIcoCor, "user")
              : lodash.get(AvatarIcoCor, "user"),
          }}
        >
          {authService.getUserInfo()!.given_name[0]}
        </Avatar>
        <b style={{ marginLeft: "15px" }}>
          {authService.getUserInfo()!.given_name}
        </b>
      </div>,
      "0",
      undefined,
      undefined,
      "avatar"
    ),
    getMenuItem(t("dashboard.sider.menu1"), "1", <AppstoreOutlined />),
    getMenuItem(t("dashboard.sider.menu2"), "2", <DashboardOutlined />),
    getMenuItem(t("dashboard.sider.menu3"), "3", <DesktopOutlined />),
    getMenuItem(t("dashboard.sider.menu4"), "4", <ExperimentOutlined />),
    getMenuItem(t("dashboard.sider.menu5"), "5", <ProjectOutlined />),
    getMenuItem(t("dashboard.sider.menu6"), "6", <UserOutlined />),
    getMenuItem(t("dashboard.sider.menu7"), "sub1", <SettingOutlined />, [
      getMenuItem(t("dashboard.sider.menu71"), "7"),
    ]),
    getMenuItem(t("dashboard.sider.menu8"), "8", <LogoutOutlined />),
  ];

  const userItems: MenuItem[] = [
    getMenuItem(
      <div>
        <Avatar
          style={{
            backgroundColor: authService
              .getUserInfo()!
              .realm_access.roles.includes("admin")
              ? lodash.get(AvatarIcoCor, "admin")
              : authService.getUserInfo()!.realm_access.roles.includes("user")
              ? lodash.get(AvatarIcoCor, "user")
              : lodash.get(AvatarIcoCor, "user"),
          }}
        >
          {authService.getUserInfo()!.given_name[0]}
        </Avatar>
        <b style={{ marginLeft: "15px" }}>
          {authService.getUserInfo()!.given_name}
        </b>
      </div>,
      "0",
      undefined,
      undefined,
      "avatar"
    ),
    getMenuItem(t("dashboard.sider.menu1"), "1", <AppstoreOutlined />),
    getMenuItem(t("dashboard.sider.menu2"), "2", <DashboardOutlined />),
    getMenuItem(t("dashboard.sider.menu3"), "3", <DesktopOutlined />),
    getMenuItem(t("dashboard.sider.menu4"), "4", <ExperimentOutlined />),
    getMenuItem(t("dashboard.sider.menu5"), "5", <ProjectOutlined />),
    getMenuItem(t("dashboard.sider.menu7"), "sub1", <SettingOutlined />, [
      getMenuItem(t("dashboard.sider.menu71"), "7"),
    ]),
    getMenuItem(t("dashboard.sider.menu8"), "8", <LogoutOutlined />),
  ];

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    if (!userInfo) {
      navigate("/login"); // Navigate to the login page if the user is not logged in
    }
    if (location.pathname === "/") {
      setSelectedKeys(["1"]);
    } else if (location.pathname === "/dashboard") {
      setSelectedKeys(["2"]);
    } else if (location.pathname === "/devicelist") {
      setSelectedKeys(["3"]);
    } else if (location.pathname === "/modules") {
      setSelectedKeys(["4"]);
    } else if (location.pathname === "/projects") {
      setSelectedKeys(["5"]);
    } else if (location.pathname === "/userlist") {
      setSelectedKeys(["6"]);
    } else if (location.pathname === "/settings") {
      setSelectedKeys(["7"]);
    }
  }, [location, navigate]);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Modal
        title={t("dashboard.sider.menu8")}
        open={isLogoutModalOpen}
        onOk={handleLogout}
        onCancel={handleCancel}
        okText={t("btn.ok")}
        cancelText={t("btn.cancel")}
      >
        <p>{t("dashboard.logoutModal.text")}</p>
      </Modal>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          {authService.getUserInfo()!.realm_access.roles.includes("admin") ? (
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={adminItems}
              selectedKeys={selectedKeys}
              onSelect={({ key }) => handleMenuSelect(key)}
            />
          ) : (
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={userItems}
              selectedKeys={selectedKeys}
              onSelect={({ key }) => handleMenuSelect(key)}
            />
          )}
        </Sider>
        <Layout>
          <Content style={{ margin: "16px 16px" }}>
            <Routes>
              <Route path="/*" element={<MainPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/devicelist" element={<DevicePage />} />
              <Route path="/modules" element={<ModulePage />} />
              <Route path="/projects" element={<ProjectPage />} />
              <Route path="/userlist" element={<UserPage />} />
              <Route path="/settings" element={<SettingPage />} />
            </Routes>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Grundfos P2 Playground ©2023 Created by TI Hub China</Footer> */}
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default EntryPage;
