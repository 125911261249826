import { IUserLogin } from "../interfaces/auth.interface";

// Color of avatar based on different roles
export const AvatarIcoCor = {
  admin: '#87d068',
  user: '#f56a00'
};

export const adminAccount: IUserLogin = {
  username: "admin",
  password: "admin@123",
};

export const TelemetryList: string[] = [
  "bio",
  "current",
  "pressure",
  "turbidity",
  "waterflow",
  "waterlevel",
  "watermeter"
];

// Color list for different tags
export const Colors = ["#f50", "#2db7f5", "#5acf28", "#108ee9", "#eec50c", "#c72b93"];
export const onlineColor = "#38c401";
export const offlineColor = "#808080";
export const disableColor = "#ff0000";

// This is mapping of role and auth (should be replaced by keycloak built-in functions in the future)
export const roleAuth = {
  admin: ['*'],
  users: ['/profile', '/devices', '/dashboard', '/settings/system']
}

// This is keycloak built-in roles that will not show in portal
export const KeycloakRoles = ["offline_access", "default-roles-p2playground", "uma_authorization"];

// Show this string in the portal if value is undefined
export type EmptyValue = '--';

export const TotalDBSize = 30 * 1024 * 1024 * 1024;