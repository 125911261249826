export interface IProject {
  id: string;
  name: string;
  stackholders: string[];
  startDate: string;
  endDate?: string;
  ideation?: string;
  conceptualization?: string;
  incubation?: string;
  viableBusiness?: string;
  scaleSaling?: string;
  archived?: string;
}

export enum PhaseState {
  Planned = 'planned',
  InProgress = 'inProgress',
  Finished = 'finished',
  Stopped = 'stopped',
  Unknown = 'unknown'
}

export interface ProjectFormDto {
  id: string;
  name: string;
  stackholders: string[];
  startDate: string;
  endDate?: string;
  status: ProjectStatus;
}

export interface ProjectStatus {
  Ideation?: string;
  Conceptualization?: string;
  Incubation?: string;
  ViableBusiness?: string;
  ScaleSaling?: string;
  Archived?: string;
}
